<template>
  <div>
    <div class="status-container">
      <div
        class="status-box"
        v-for="status in statuses"
        :key="status"
        @click="searchByStatus(status)"
        style="position: relative"
      >
        <div class="status-info">
          <p class="status-label">{{ getStatusLabel(status) }}</p>
          <div
            class="count"
            :style="{
              color: 'white',
              'background-color': '#ff8080',
              position: 'absolute',
              top: '-10px' /* Adjust this value as needed */,
              right: '-10px' /* Adjust this value as needed */,
              padding: '4px 8px',
              'border-radius': '50%',
            }"
          >
            {{ statusCounts[status] !== undefined ? statusCounts[status] : 0 }}
          </div>
        </div>
      </div>
    </div>

    <v-card-title>
      <p class="my-2">検索結果：{{ this.searchResults.length }}件</p>
    </v-card-title>

    <div class="search-toggle-container">
      <button class="search-toggle-btn" @click="toggleSearchFields">
        <i
          :class="
            searchFieldsVisible ? 'fas fa-toggle-on' : 'fas fa-toggle-off'
          "
        ></i>
        <span>{{
          searchFieldsVisible ? "検索項目を非表示" : "検索項目を表示"
        }}</span>
      </button>
    </div>

    <form @submit.prevent="search" v-if="showSearchFields" class="py-3">
      <div class="row">
        <!-- 見積IDの入力フィールド -->
        <div class="col-md-3 mb-3">
          <label for="estimateId">見積ID</label>
          <input
            type="number"
            id="estimateId"
            v-model="searchCriteria.id"
            class="form-control"
          />
        </div>
      </div>

      <!-- エンドユーザー情報 -->
      <div class="row">
        <div class="col-md-9">
          <!-- エンドユーザー名の入力フィールド -->
          <div class="row">
            <div class="col-md-4 mb-3">
              <label for="customerName">エンドユーザー名</label>
              <input
                type="text"
                id="customerName"
                v-model="searchCriteria.customer_name"
                class="form-control"
              />
            </div>

            <!-- エンドユーザー名(かな)の入力フィールド -->
            <div class="col-md-4 mb-3">
              <label for="customerNameKana">エンドユーザー名(かな)</label>
              <input
                type="text"
                id="customerNameKana"
                v-model="searchCriteria.customer_name_kana"
                class="form-control"
              />
            </div>

            <!-- エンドユーザーKentemIDの入力フィールド -->
            <div class="col-md-4 mb-3">
              <label for="customerKentemId">エンドユーザーKentemID</label>
              <input
                type="text"
                id="customerKentemId"
                v-model="searchCriteria.customer_kentem_id"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- 販売店情報 -->
      <div class="row">
        <!-- 販売店名の入力フィールド -->
        <div class="col-md-4 mb-3">
          <label for="salesName">販売店名</label>
          <input
            type="text"
            id="salesName"
            v-model="searchCriteria.sales_name"
            class="form-control"
          />
        </div>

        <!-- 販売店名(かな)の入力フィールド -->
        <div class="col-md-4 mb-3">
          <label for="salesNameKana">販売店名(かな)</label>
          <input
            type="text"
            id="salesNameKana"
            v-model="searchCriteria.sales_name_kana"
            class="form-control"
          />
        </div>

        <!-- 販売店KentemIDの入力フィールド -->
        <div class="col-md-4 mb-3">
          <label for="salesKentemId">販売店KentemID</label>
          <input
            type="text"
            id="salesKentemId"
            v-model="searchCriteria.sales_kentem_id"
            class="form-control"
          />
        </div>
      </div>

      <!-- 日付情報 -->
      <div class="row">
        <!-- 受注日の入力フィールド -->
        <div class="col-md-4 mb-3">
          <label for="orderDate">受注日</label>
          <input
            type="date"
            id="orderDate"
            v-model="searchCriteria.order_date"
            class="form-control"
          />
        </div>

        <!-- 出荷日の入力フィールド -->
        <div class="col-md-4 mb-3">
          <label for="shipmentDate">出荷日</label>
          <input
            type="date"
            id="shipmentDate"
            v-model="searchCriteria.shipment_date"
            class="form-control"
          />
        </div>

        <!-- 売上日の入力フィールド -->
        <div class="col-md-4 mb-3">
          <label for="salesDate">売上日</label>
          <input
            type="date"
            id="salesDate"
            v-model="searchCriteria.sales_date"
            class="form-control"
          />
        </div>
      </div>

      <!-- その他情報 -->
      <div class="row">
        <!-- 売上担当の入力フィールド -->
        <div class="col-md-4 mb-3">
          <label for="salesRepresentative">売上担当</label>
          <input
            type="text"
            id="salesRepresentative"
            v-model="searchCriteria.sales_representative"
            class="form-control"
          />
        </div>

        <!-- 営業所の入力フィールド -->
        <div class="col-md-4 mb-3">
          <label for="departmentCode">営業所</label>
          <input
            type="number"
            id="departmentCode"
            v-model="searchCriteria.department_code"
            class="form-control"
          />
        </div>

        <!-- 出荷確認日時の入力フィールド -->
        <div class="col-md-4 mb-3">
          <label for="orderConfirmationCreatedAt">出荷確認日時</label>
          <input
            type="datetime-local"
            id="orderConfirmationCreatedAt"
            v-model="searchCriteria.order_confirmation_created_at"
            class="form-control"
          />
        </div>
      </div>

      <!-- 日付範囲検索 -->
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="orderDateStart">受注日(開始)</label>
          <input
            type="date"
            id="orderDateStart"
            v-model="searchCriteria.order_date_start"
            class="form-control"
          />
        </div>

        <div class="col-md-4 mb-3">
          <label for="orderDateEnd">受注日(終了)</label>
          <input
            type="date"
            id="orderDateEnd"
            v-model="searchCriteria.order_date_end"
            class="form-control"
          />
        </div>
      </div>

      <!-- 出荷日範囲検索 -->
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="shipmentDateStart">出荷日(開始)</label>
          <input
            type="date"
            id="shipmentDateStart"
            v-model="searchCriteria.shipment_date_start"
            class="form-control"
          />
        </div>

        <div class="col-md-4 mb-3">
          <label for="shipmentDateEnd">出荷日(終了)</label>
          <input
            type="date"
            id="shipmentDateEnd"
            v-model="searchCriteria.shipment_date_end"
            class="form-control"
          />
        </div>
      </div>

      <!-- 売上日範囲検索 -->
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="salesDateStart">売上日(開始)</label>
          <input
            type="date"
            id="salesDateStart"
            v-model="searchCriteria.sales_date_start"
            class="form-control"
          />
        </div>

        <div class="col-md-4 mb-3">
          <label for="salesDateEnd">売上日(終了)</label>
          <input
            type="date"
            id="salesDateEnd"
            v-model="searchCriteria.sales_date_end"
            class="form-control"
          />
        </div>
      </div>

      <!-- 税込み合計金額の入力フィールド -->
      <div class="row">
        <div class="col-md-4 mb-3">
          <label for="totalAmountTax">税込み合計金額</label>
          <input
            type="number"
            id="totalAmountTax"
            v-model="searchCriteria.total_amount_tax"
            class="form-control"
          />
        </div>
      </div>

      <!-- 検索ボタン -->
      <div class="row">
        <div class="col-md-12">
          <button type="submit" class="btn btn-primary">検索</button>
        </div>
      </div>
    </form>

    <!-- 検索結果を表示する場所 -->
    <div v-if="isLoading" class="loading-container">
      <i class="fas fa-spinner fa-spin"></i>
    </div>
    <div v-else>
      <v-card class="mt-2" v-if="this.searchResults.length > 0">
        <v-card-text>
          <table class="table">
            <tr>
              <th>ID</th>
              <th>取引先</th>
              <th>販売店</th>
              <th>ステータス</th>
              <th>税込合計金額</th>
              <th>担当営業</th>
              <th>商談No</th>
              <th>受注日</th>
              <th>出荷日</th>
              <th>売上日</th>
              <th>受注確定日時</th>
              <th><i class="fas fa-paperclip"></i></th>
            </tr>
            <tr v-for="(data, index) in searchResults" :key="index">
              <td>
                <a :href="'/admin/estimate/conf/' + data.id">{{ data.id }}</a>
              </td>
              <td>
                <a :href="'/kentem_information/' + data.customer_kentem_id">
                  {{
                    data.is_non_users_order == 1
                      ? data.shipping_company
                      : customerNames[data.id]
                  }}</a
                >
              </td>
              <td>
                {{
                  data.is_non_users_order == 1
                    ? data.billing_company
                    : salesNames[data.id]
                }}
              </td>
              <td>
                {{ getStatusLabel(data.status) }}
              </td>
              <td>{{ formatAmount(data.total_amount_tax) }}</td>
              <td>{{ data.contact_name }}</td>
              <td>{{ data.opportunity_number }}</td>
              <td>{{ data.order_date }}</td>
              <td>{{ data.shipment_date }}</td>
              <td>{{ data.sales_date }}</td>
              <td>{{ data.order_received_confirmation_created_at }}</td>
              <td>
                <i v-if="data.has_additional_files" class="fas fa-check"></i>
              </td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
      <v-card v-else class="mt-2">
        <v-card-text>
          {{ $t("No results found.") }}
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import "vuetify/dist/vuetify.min.css";
import Vue from "vue";
import Vuetify from "vuetify";
Vue.use(Vuetify);
export default {
  props: {
    token: {
      type: String,
      required: true,
    },
    customerNames: {
      type: Object,
      required: true,
    },
    salesNames: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      statuses: [6, 7, 8], // ステータス一覧
      searchTerm: "", // 検索語句
      searchCriteria: {
        id: null,
        customer_name: "",
        customer_name_kana: "",
        customer_kentem_id: "",
        customer_shokon_code: "",
        sales_name: "",
        sales_name_kana: "",
        sales_kentem_id: "",
        sales_shokon_code: "",
        order_date: null,
        shipment_date: null,
        sales_date: null,
        order_date_start: null,
        order_date_end: null,
        shipment_date_start: null,
        shipment_date_end: null,
        sales_date_start: null,
        sales_date_end: null,
        sales_representative: "",
        department_code: null,
        shipmentConfirmationDateTime: null,
        total_amount_tax: null,
      },
      isLoading: true, // ローディング状態を管理するデータ
      searchResults: [], // 検索結果
      showSearchFields: false, // 検索項目の表示/非表示を制御するデータプロパティ
      searchFieldsVisible: true,
      statusCounts: {},
    };
  },
  computed: {},
  created() {
    this.fetchInitialResults();
    this.fetchStatusCounts();
  },
  methods: {
    fetchInitialResults() {
      this.isLoading = true;
      Axios.get("/api/v1/estimate/order/init", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => {
          if (res.data) {
            this.searchResults = res.data;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    /**
     *
     * @param
     */
    search() {
      Axios.get("/api/v1/estimate/order/search", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
        params: this.searchCriteria,
      })
        .then((res) => {
          if (res.data) {
            this.searchResults = res.data;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    toggleSearchFields() {
      this.showSearchFields = !this.showSearchFields; // 検索項目の表示/非表示を切り替える
    },
    /**
     *
     * @param status
     */
    searchByStatus(status) {
      Axios.get(`/api/v1/estimate/order/search/${status}`, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      })
        .then((res) => {
          if (res.data) {
            this.searchResults = res.data;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getStatusCount(status) {
      return new Promise((resolve, reject) => {
        Axios.get(`/api/v1/estimate/order/status/count/${status}`, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
          .then((res) => {
            if (res.data) {
              resolve(res.data); // 成功時にデータを返す
            } else {
              reject(new Error("No data received")); // データがない場合はエラーを返す
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error); // エラーが発生した場合はエラーを返す
          });
      });
    },
    getStatusLabel(status) {
      switch (status) {
        case 6:
          return "受注保留中";
        case 7:
          return "受注未処理";
        case 8:
          return "受注確定済み";
        default:
          return "";
      }
    },
    formatAmount(amount) {
      // 数値が存在しない場合やNaNの場合、空文字を返す（エラー防止）
      if (!amount || isNaN(amount)) {
        return "";
      }

      // 数値を通貨の形式にフォーマットする
      return new Intl.NumberFormat("ja-JP", {
        style: "currency",
        currency: "JPY",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(amount);
    },
    fetchStatusCounts() {
      // Promise.allを使って非同期の処理をまとめる
      Promise.all(
        this.statuses.map((status) => {
          return this.getStatusCount(status)
            .then((count) => {
              // データ取得後にstatusCountsにセット
              this.$set(this.statusCounts, status, count);
            })
            .catch((error) => {
              console.error(error);
            });
        })
      );
    },
  },
};
</script>

<style scoped>
/* Font Awesome のスタイルシートを読み込む */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css");

.status-container {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
}

.status-box {
  background-color: #fff; /* 白色の背景 */
  color: #000; /* 黒色のテキスト */
  border-radius: 8px;
  padding: 10px;
  margin: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.status-box:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3); /* 影の調整 */
}

.status-info {
  text-align: center;
}

.status-label {
  margin-bottom: 6px;
  font-size: 13px;
  font-weight: bold;
}

.count-wrapper {
  position: relative;
  text-align: right;
}
.count {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 8px;
  border-radius: 50%;
}

.search-toggle-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-left: 20px;
}

.search-toggle-btn {
  display: flex;
  align-items: center;
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-toggle-btn:hover {
  background-color: #2980b9;
}

.search-toggle-btn i {
  margin-right: 8px;
}
</style>
