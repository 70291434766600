<template>
  <div class="col-xs-12 col-sm-12 col-md-12 py-0">
    <div class="form-row">
      <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <div class="form-group">
          <label class="col-form-label">
            <span>{{ shippingLabel }}</span>
            <span v-if="shippingRequired" class="badge badge-primary">{{
              $t("required")
            }}</span>
            <span v-else class="badge badge-secondary">{{
              $t("optional")
            }}</span>
          </label>
          <div class="input-group">
            <input
              type="text"
              :value="shippingLabelValue"
              :readonly="true"
              class="form-control"
            >
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="clearShippingInfo"
                aria-label="Clear"
              >
                ×
              </button>
            </div>
          </div>
          <input
            type="hidden"
            name="shipping_instructions_id"
            :value="shippingFormValue"
            :required="shippingRequired"
            readonly="readonly"
          >
          <v-dialog
            v-model="shippingDialog"
            persistent
            scrollable
            max-width="1000px"
            style="z-index: 1050"
          >
            <template #activator="{ on }">
              <v-btn color="primary" dark v-on="on">
                {{"参照"}}
              </v-btn>
            </template>
            <v-card>
              <v-toolbar color="light-blue darken-4" dark>
                <v-toolbar-title>{{ shippingTitle }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon @click="shippingDialog = false">
                  <v-icon>fas fa-window-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <v-col v-for="header in shippingHeaders" :key="header.value">
                          <v-text-field
                            v-model="searchTerms[header.value]"
                            :label="header.text"
                            append-icon="mdi-magnify"
                            single-line
                            hide-details
                            clearable
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-data-table
                        :headers="shippingHeaders"
                        :items="filteredItems"
                        :items-per-page="10"
                        class="elevation-1"
                        :loading="shippingLoading"
                      >
                        <template #item="{ item }">
                          <tr @click="selectShippingItem(item)">
                            <td v-for="header in shippingHeaders" :key="header.value">
                              {{ item[header.value] }}
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="shippingDialog = false">
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shippingLabel: {
      type: String,
      default: "出荷指示情報",
    },
    shippingItems: {
      type: Array,
      default: () => [],
    },
    shippingHeaders: {
      type: Array,
      default: () => [],
    },
    shippingRequired: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: Number,
    },
  },
  data() {
    return {
      shippingDialog: false,
      shippingFormValue: this.selected,
      shippingLabelValue: this.getShippingLabelValue(this.selected),
      shippingLoading: false,
      shippingTitle: "出荷指示情報一覧",
      searchTerms: {},
    };
  },
  computed: {
    filteredItems() {
      return this.shippingItems.filter(item => {
        return this.shippingHeaders.every(header => {
          const searchTerm = this.searchTerms[header.value]?.toLowerCase() || "";
          return String(item[header.value]).toLowerCase().includes(searchTerm);
        });
      });
    },
  },
  methods: {
    selectShippingItem(item) {
      if (item && item.id) {
        this.shippingFormValue = item.id; // 出荷指示IDを設定
        this.shippingLabelValue = `${item.estimate_id || ''}｜${item.id}｜${item.company_name || ''}`; // 見積ID｜出荷指示データID｜会社名
        this.shippingDialog = false;
        this.$emit('shipmentdate-change', item.id);
      } else {
        console.warn('Invalid item selected:', item);
      }
    },
    getShippingLabelValue(selectedId) {
      const selectedItem = this.shippingItems.find(item => item.id === selectedId);
      return selectedItem
        ? `${selectedItem.estimate_id}｜${selectedItem.id}｜${selectedItem.company_name}` // 見積ID｜出荷指示データID｜会社名
        : "";
    },
    clearShippingInfo() {
      this.shippingLabelValue = ""; // ラベルを空にする
      this.shippingFormValue = ""; // shipping_instructions_idを空にする
    },
  },
  mounted() {
    // 検索用の初期値を設定
    this.shippingHeaders.forEach(header => {
      this.$set(this.searchTerms, header.value, "");
    });
  },
};
</script>