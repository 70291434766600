<template>
	<v-card class="mx-auto" outlined>

		<!-- 検索フォーム -->
		<v-form @submit.prevent="onSearch">
			<h2>検索</h2>
			<!-- ワンクリック検索ボタン -->
			<v-row>
				<v-col cols="6">
					<v-btn class="amber" @click="onStockListClick">在庫リスト</v-btn>
					<v-btn class="amber" @click="onShipmentListClick">出荷リスト</v-btn>
				</v-col>
			</v-row>
			<!-- 検索フォーム開閉ボタン -->
			<v-btn
			@click="isFormVisible = !isFormVisible"
			class="my-2 mr-2"
			>
			{{ isFormVisible ? '詳細を閉じる' : '詳細を開く' }}
			</v-btn>
			<v-row v-if="isFormVisible">
				<v-col cols="2">
					<v-text-field
						v-model="searchId"
						label="ID"
						clearable
						hide-details="auto"
					></v-text-field>
				</v-col>
				<v-col cols="2">
					<v-text-field
						v-model="searchEstimateId"
						label="見積ID"
						clearable
						hide-details="auto"
					></v-text-field>
				</v-col>
				<v-col cols="4">
					<label class="grey--text" style="position: absolute;">ステータス</label>
					<v-select
						v-model="searchStatus"
						:options="statusListForSelect"
						clearable
						class="stock-select"
						multiple
					></v-select>
				</v-col>
				<v-col cols="2" v-if="dateSearchMode==='string-match'">
					<datepicker-field
						:value.sync="searchDate"
						label="納品日"
						clearable
						hide-details="auto"
					></datepicker-field>
				</v-col>
				<v-col cols="2" v-if="dateSearchMode==='date-start-end'">
					<datepicker-field
						:value.sync="searchDateStart"
						label="納品日 指定日以降"
						clearable
						hide-details="auto"
					></datepicker-field>
				</v-col>
				<v-col cols="2" v-if="dateSearchMode==='date-start-end'">
					<datepicker-field
						:value.sync="searchDateEnd"
						label="納品日 指定日以前"
						clearable
						hide-details="auto"
					></datepicker-field>
				</v-col>
				<v-col cols="2" v-if="displayModelSearch">
					<v-text-field
						v-model="searchModel"
						label="製品名"
						clearable
						hide-details="auto"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row v-if="isFormVisible">
				<v-col cols="2">
					<v-text-field
						v-model="searchKentemId"
						label="KENTEM ID"
						clearable
						hide-details="auto"
					></v-text-field>
				</v-col>
				<v-col cols="2">
					<v-text-field
						v-model="searchShokonCode"
						label="商魂コード"
						clearable
						hide-details="auto"
					></v-text-field>
				</v-col>
				<v-col cols="2">
					<v-text-field
						v-model="searchCompany"
						label="会社名"
						clearable
						hide-details="auto"
					></v-text-field>
				</v-col>
				<v-col cols="2">
					<datepicker-field
						:value.sync="searchShipStart"
						label="出荷日 指定日以降"
						:clearable="true"
						hide-details="auto"
					></datepicker-field>
				</v-col>
				<v-col cols="2">
					<datepicker-field
						:value.sync="searchShipEnd"
						label="出荷日 指定日以前"
						:clearable="true"
						hide-details="auto"
					></datepicker-field>
				</v-col>
				<v-col cols="2">
					<v-text-field
						v-model="searchMemo"
						label="備考"
						clearable
						hide-details="auto"
					></v-text-field>
				</v-col>
			</v-row>
			<v-row v-if="isFormVisible">
				<v-col cols="6">
					<v-btn color="grey" @click="onClearBtnClick">検索クリア</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<!-- 一括変更フォーム -->
		<h2 v-if="canEdit && isFormVisible">一括変更</h2>
		<v-row v-if="canEdit">
			<!-- 一括変更項目の選択 -->
			<v-col v-if="isFormVisible" cols="2">
				<v-select
					v-model="bulkUpdateField"
					:options="bulkOptions"
					clearable
					class="stock-select"
				></v-select>
			</v-col>
			<!-- 選択された項目に応じてフォームを表示 -->
			<v-col v-if="isFormVisible" cols="2">
				<v-text-field
					v-if="bulkUpdateField === ''"
					readonly
					label=""
				></v-text-field>
				<datepicker-field
					v-if="bulkUpdateField === '納品日'"
					:value.sync="bulkReceiveDate"
					label="納品日"
					clearable
					hide-details="auto"
				></datepicker-field>
				<label v-if="bulkUpdateField === 'ステータス'" class="grey--text" style="position: absolute;">ステータス</label>
				<v-select
					v-if="bulkUpdateField === 'ステータス'"
					v-model="bulkStatus"
					:options="statusListForBulkUpdateSelect"
					clearable
					class="stock-select"
				></v-select>
			</v-col>
			<!-- 変更ボタン -->
			<v-col v-if="isFormVisible" cols="2">
				<v-btn color="primary" @click="bulkUpdate">変更</v-btn>
			</v-col>
			<!-- 開閉ボタン用プレースホルダ -->
			<v-col v-if="!isFormVisible" cols="6"></v-col>
			<v-col cols="6">
				<stock-hard-addform v-if="canEdit"
					:segment-id="segmentId"
					:status-list-for-select="statusListForSelect"
					:status-list-for-get-name="statusListForGetName"
					:selected-model-id="selectedModelId"
					:selected-model-name="selectedModelName"
					:stock-model-list-for-select="stockModelListForSelect"
					:token="token"
				></stock-hard-addform>
			</v-col>
		</v-row>

		<!-- データテーブル -->
		<v-data-table
			v-model="selectedItems"
			:headers="headers"
			:items="filteredItems"
			:items-per-page.sync="itemsPerPage"
			:sort-by="sortBy"
			:sort-desc="sortDesc"
			:page.sync="page"
			show-select
			class="stock-table mt-5"
		>
			<!-- 件数表示 -->
			<template #top>
				<div class="d-flex justify-end align-center">
					<span>{{ paginationText }}</span>
				</div>
			</template>

			<template #[`item.id`]="{ item }">
				<a :href="getEditUrl(baseurl, item)">{{ item.id }}</a>
			</template>
			<template #[`item.status`]="{ item }">
				<span :class="getStatusColorClass(item.status)">{{ getStatusName(item.status) }}</span>
			</template>
			<template #[`item.estimate_id`]="{ item }">
				<span v-if="item.estimate_id!==null" v-html="item.estimate_id"></span>
			</template>
			<template #[`item.delete`]="{ item }">
				<form
					:action="getDeleteUrl(baseurl, item)"
					method="post"
				>
					<input
						type="hidden"
						name="_token"
						:value="getCsrfToken()"
					>
					<input
						type="hidden"
						name="_method"
						value="DELETE"
					>
					<v-btn
						v-confirm="deleteConfirmationLabel"
						type="submit"
						icon
						class="bg-gradient-danger shadow"
					>
						<i class="far fa-trash-alt" />
					</v-btn>
				</form>
			</template>
		</v-data-table>
	</v-card>
</template>

<script>
import axios from 'axios';
export default {
  props: {
    canEdit: {
      type: Boolean,
      required: true,
    },
    segmentId: {
      type: Number,
      required: true,
    },
    baseurl: {
      type: String,
      required: true,
    },
    canCreate: {
      type: Boolean,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    idName: {
      type: String,
      default: "id",
    },
    statusListForSelect: {
      type: Array,
      required: true,
    },
    statusListForBulkUpdateSelect: {
      type: Array,
      required: true,
    },
    statusListForGetName: {
      type: Object,
      required: true,
    },
    selectedModelId: {
      type: Number,
      required: true
    },
    selectedModelName: {
      type: String,
      required: true
    },
    stockModelListForSelect: {
      type: Array,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    presetSearchConditions: {
      type: Object,
      required: true,
    },
	displayModelSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateSearchMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isFormVisible: false,
      deleteConfirmationLabel: this.$i18n.translate("delete ok?"),
      searchId: "",
      searchEstimateId: "",
      searchStatus: "",
      searchCompany: "",
      searchKentemId: "",
      searchMemo: "",
	  searchShokonCode: "",
      searchDate: "",
      searchDateStart: "",
      searchDateEnd: "",
      searchModel: "",
      searchShipStart: "",
      searchShipEnd: "",
      sortBy: "id",
      sortDesc: true,
      bulkUpdateField: "", // 選択された一括変更項目
      bulkOptions: ["ステータス", "納品日"], // 一括変更で選択できる項目
      bulkStatus: null,
      bulkReceiveDate: "",
      selectedItems: [],
	  page: 1, // ページ番号
	  itemsPerPage: 50, // 1ページあたりの項目数
    };
  },
  computed: {
	filteredItems() {
		return this.items.filter(item => {
		const matchesId = this.searchId ? item.id.toString().includes(this.searchId) : true;
		const matchesEstimateId = this.searchEstimateId ? item.estimate_id?.toString().includes(this.searchEstimateId) : true;
		const matchesStatus = this.searchStatus && this.searchStatus.length > 0 
			? this.searchStatus.some(status => status.value === item.status) 
			: true;
		const matchesCompany = this.searchCompany ? item.company_name?.includes(this.searchCompany) : true;
		const matchesKentemId = this.searchKentemId ? item.kentem_id?.includes(this.searchKentemId) : true;
		const matchesShokonCode = this.searchShokonCode ? item.shokon_code?.includes(this.searchShokonCode) : true;
		const matchesDate = this.searchDate ? item.receive_date?.includes(this.searchDate) : true;
        const matchesDateStart = this.searchDateStart
          ? (item.receive_date !== null && item.receive_date !== '' && new Date(item.receive_date) >= new Date(this.searchDateStart))
          : true;
        const matchesDateEnd = this.searchDateEnd
          ? (item.receive_date !== null && item.receive_date !== '' && new Date(item.receive_date) <= new Date(this.searchDateEnd))
          : true;
		const matchesModel = this.searchModel ? item.model_name?.includes(this.searchModel) : true;
        const matchesShipStart = this.searchShipStart
          ? (item.shipment_date !== null && item.shipment_date !== '' && new Date(item.shipment_date) >= new Date(this.searchShipStart))
          : true;
        const matchesShipEnd = this.searchShipEnd
          ? (item.shipment_date !== null && item.shipment_date !== '' && new Date(item.shipment_date) <= new Date(this.searchShipEnd))
          : true;
		const matchesMemo = this.searchMemo ? item.memo?.includes(this.searchMemo) : true;

		return matchesId && matchesEstimateId && matchesStatus && matchesCompany && matchesKentemId && matchesShokonCode && matchesDate && matchesDateStart && matchesDateEnd && matchesModel && matchesShipStart && matchesShipEnd && matchesMemo;
		});
	},
	paginationText() {
		const total = this.filteredItems.length;
		const start = (this.page - 1) * this.itemsPerPage + 1;
		const end = this.itemsPerPage!==-1 ? Math.min(this.page * this.itemsPerPage, this.filteredItems.length) : total;
		return `${start}-${end} 件目 / ${total}件`;
	}
  },
  methods: {
    getStatusColorClass(status) {
      const statusColorMap = new Map([
        [0, "orange--text font-weight-bold"],
        [1, "orange--text text--darken-2 font-weight-bold"],
        [2, "light-green--text text--accent-4 font-weight-bold"],
        [3, "pink--text text--lighten-1 font-weight-bold"],
        [4, "grey--text font-weight-bold"],
        [5, "pink--text text--lighten-1 font-weight-bold"],
        [6, "orange--text text--darken-2 font-weight-bold"],
        [7, "pink--text text--lighten-1 font-weight-bold"],
        [8, "red--text font-weight-bold"],
        [9, "grey--text font-weight-bold"],
        [10, "purple--text font-weight-bold"],
      ]);
      return statusColorMap.get(status) || "";
    },
    getStatusName(status) {
      return this.statusListForGetName[status];
    },
    isDeletableItem(item) {
      return item.delete;
    },
    getCsrfToken() {
      return document.getElementsByName("csrf-token")[0].content;
    },
    getEditUrl(baseurl, item) {
      return "/" + baseurl + "/edit/" + item[this.idName];
    },
    getDeleteUrl(baseurl, item) {
      return "/" + baseurl + "/" + item[this.idName];
    },
	bulkUpdate() {
		if (!this.selectedItems || this.selectedItems.length === 0) {
			alert("一括変更するアイテムを選択してください。");
			return;
		}

		if (!this.bulkUpdateField) {
			alert("一括変更する項目を選択してください。");
			return;
		}

		// 更新データの準備
		const data = {
			ids: this.selectedItems.map(item => item.id),
		};

		if (this.bulkUpdateField === "ステータス") {
			if (!this.bulkStatus) {
			alert("ステータスを選択してください。");
			return;
			}
			data.status = this.bulkStatus.value;
		} else if (this.bulkUpdateField === "納品日") {
			if (!this.bulkReceiveDate) {
			alert("納品日を入力してください。");
			return;
			}
			data.receive_date = this.bulkReceiveDate;
		}

		axios.post("/api/v1/stocks/bulk-update", data, {
			headers: {
			'Content-Type': 'application/json',
			'Authorization': "Bearer " + this.token
			}
		})
		.then(response => {
			alert("一括変更が成功しました。");
			window.location.reload();
		})
		.catch(error => {
			if (error.response && error.response.status !== 200) {
				alert(error.response.data.message);
			} else {
				alert("一括変更に失敗しました。");
			}
		});
	},
	// ワンクリックで在庫リストを表示
	onStockListClick() {
		this.searchStatus = this.presetSearchConditions['stock']['searchStatus'];
		this.sortBy = this.presetSearchConditions['stock']['sortBy'];
		this.sortDesc = this.presetSearchConditions['stock']['sortDesc'];
	},
	// ワンクリックで出荷リストを表示
	onShipmentListClick() {
		this.searchStatus = this.presetSearchConditions['shipment']['searchStatus'];
		this.sortBy = this.presetSearchConditions['shipment']['sortBy'];
		this.sortDesc = this.presetSearchConditions['shipment']['sortDesc'];
	},
	// ワンクリックで検索クリア
	onClearBtnClick() {
		this.searchStatus = [];
		this.searchId = '';
		this.searchEstimateId = '';
		this.searchStatus = '';
		this.searchDate = '';
		this.searchDateStart = '';
		this.searchDateEnd = '';
		this.searchModel = '';
		this.searchKentemId = '';
		this.searchShokonCode = '';
		this.searchCompany = '';
		this.searchShipStart = '';
		this.searchShipEnd = '';
		this.searchMemo = '';
		this.sortBy = "id";
		this.sortDesc = true;
	},
  },
};
</script>
<style scoped>
.v-card {
  border: none;
}
h2{
  font-size: 14px;
  font-weight: bold;
  margin-top: 50px;
}
td:has([v-slot~="delete"]) {
  border: none;
  visibility: hidden;
}
.stock-select{
    padding-top: 12px;
    margin-top: 4px;
    .vs__dropdown-toggle {
      border: none; /* 枠線を消す */
      color: rgb(0 0 0 / .42);
      border-radius: 0;
      border-bottom: 1px solid; /* 枠線を消す */
    }
}
.stock-table{
  width: 1200px;
  thead{
    background: #BBDEFB;
    th{
      font-weight: bold;
    }
  }
}
</style>