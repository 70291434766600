<template>
  <div class="col-xs-12 col-sm-12 col-md-12 py-0">
    <div class="form-row">
      <div class="col-xs-12 col-sm-6 col-md-6 py-0">
        <div v-if="salesKentemId != 'P1241095'" class="form-group">
          <label class="col-form-label">
            <span>{{ customerLabelData }}</span>
            <span v-if="customerRequired" class="badge badge-primary">{{
              $t("required")
            }}</span>
            <span v-else class="badge badge-secondary">{{
              $t("optional")
            }}</span>
          </label>
          <input
            type="text"
            :value="customerLabelValue"
            :disabled="this.customerDisabled"
            :readonly="this.customerReadonly"
            :required="customerRequired"
            class="form-control"
          />
          <input
            type="hidden"
            name="shipping_company"
            :value="customerFormValue"
            :required="customerRequired"
            readonly="readonly"
          />
          <input
            type="hidden"
            name="shipping_shokon_code"
            :value="customerShokonCodeFormValue"
            :required="customerRequired"
            readonly="readonly"
          />
          <v-dialog
            v-model="customerDialog"
            persistent
            scrollable
            max-width="1000px"
            style="z-index: 1050"
          >
            <template #activator="{ on }">
              <v-btn color="primary" dark v-on="on">
                {{ $t("reference") }}
              </v-btn>
            </template>
            <v-card>
              <v-toolbar color="light-blue darken-4" dark>
                <v-toolbar-title>{{ customerTitleData }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon>
                  <v-icon @click="customerDialog = false">
                    fas fa-window-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <ref-customerset-customercard
                        :callback="selectCustomerItem"
                        :customer-headers="customerHeaders"
                        :customer-items="customerItems"
                        :customer-loading="customerLoading"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  text
                  @click="customerDialog = false"
                >
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="salesDialog"
            persistent
            scrollable
            max-width="1000px"
            style="z-index: 1050"
          >
            <template #activator="{ on }">
              <v-btn color="primary" dark v-on="on">
                {{ $t("sales_reference") }}
              </v-btn>
            </template>
            <v-card>
              <v-toolbar color="light-blue darken-4" dark>
                <v-toolbar-title>{{ salesTitleData }}</v-toolbar-title>
                <v-spacer />
                <v-btn icon>
                  <v-icon @click="salesDialog = false">
                    fas fa-window-close
                  </v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <ref-customerset-customercard
                        :callback="selectCustomerItem"
                        :customer-headers="linkDealerHeaders"
                        :customer-items="linkDealerItems"
                        :customer-loading="salesLoading"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="blue darken-1" text @click="salesDialog = false">
                  {{ $t("close") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <div class="form-row">
            <label class="col-form-label">
              <span>{{ customerZipDefaultLabel }}</span>
              <span v-if="customerRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerZipFormValue"
              name="shipping_zip"
              :disabled="this.customerZipDisabled"
              :readonly="this.customerZipReadonly"
              :required="customerZipRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ customerAdd1DefaultLabel }}</span>
              <span v-if="customerRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerAdd1FormValue"
              name="shipping_address1"
              :disabled="this.customerAdd1Disabled"
              :readonly="this.customerAdd1Readonly"
              :required="customerAdd1Required"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ customerAdd2DefaultLabel }}</span>
              <span v-if="customerAdd2Required" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerAdd2FormValue"
              name="shipping_address2"
              :disabled="this.customerAdd2Disabled"
              :readonly="this.customerAdd2Readonly"
              class="form-control"
            />
          </div>
          <div class="form-row">
            <label class="col-form-label">
              <span>{{ customertNameDefaultLabel }}</span>
              <span v-if="customertNameRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customertNameFormValue"
              name="shipping_contact_name"
              :disabled="this.customertNameDisabled"
              :readonly="this.customertNameReadonly"
              :required="customertNameRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ customerTelDefaultLabel }}</span>
              <span v-if="customerRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerTelFormValue"
              name="shipping_tel"
              :disabled="this.customerTelDisabled"
              :readonly="this.customerTelReadonly"
              :required="customerTelRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ customerMailaddressDefaultLabel }}</span>
              <span
                v-if="customerMailaddressRequired"
                class="badge badge-primary"
                >{{ $t("required") }}</span
              >
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerMailaddressFormValue"
              name="shipping_mail_address"
              :disabled="this.customerMailaddressDisabled"
              :readonly="this.customerMailaddressReadonly"
              :required="customerMailaddressRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ customerMailaddress2DefaultLabel }}</span>
              <span
                v-if="customerMailaddress2Required"
                class="badge badge-primary"
                >{{ $t("required") }}</span
              >
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerMailaddress2FormValue"
              name="shipping_mail_address2"
              :disabled="this.customerMailaddress2Disabled"
              :readonly="this.customerMailaddress2Readonly"
              :required="customerMailaddress2Required"
              class="form-control"
            />
          </div>
        </div>
        <div v-else class="form-group">
          <label class="col-form-label">
            <span>{{ customerLabelData }}</span>
            <span v-if="customerRequired" class="badge badge-primary">{{
              $t("required")
            }}</span>
            <span v-else class="badge badge-secondary">{{
              $t("optional")
            }}</span>
          </label>
          <input
            type="text"
            :value="customerFormValue"
            name="shipping_company"
            :disabled="this.customerDisabled"
            :readonly="this.customerReadonly"
            :required="customerRequired"
            class="form-control"
          />
          <div class="form-row">
            <label class="col-form-label">
              <span>{{ customerZipDefaultLabel }}</span>
              <span v-if="customerRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerZipFormValue"
              name="shipping_zip"
              :disabled="this.customerZipDisabled"
              :readonly="this.customerZipReadonly"
              :required="customerZipRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ customerAdd1DefaultLabel }}</span>
              <span v-if="customerRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerAdd1FormValue"
              name="shipping_address1"
              :disabled="this.customerAdd1Disabled"
              :readonly="this.customerAdd1Readonly"
              :required="customerAdd1Required"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ customerAdd2DefaultLabel }}</span>
              <span v-if="customerAdd2Required" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerAdd2FormValue"
              name="shipping_address2"
              :disabled="this.customerAdd2Disabled"
              :readonly="this.customerAdd2Readonly"
              class="form-control"
            />
          </div>
          <div class="form-row">
            <label class="col-form-label">
              <span>{{ customertNameDefaultLabel }}</span>
              <span v-if="customertNameRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customertNameFormValue"
              name="shipping_contact_name"
              :disabled="this.customertNameDisabled"
              :readonly="this.customertNameReadonly"
              :required="customertNameRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ customerTelDefaultLabel }}</span>
              <span v-if="customerRequired" class="badge badge-primary">{{
                $t("required")
              }}</span>
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerTelFormValue"
              name="shipping_tel"
              :disabled="this.customerTelDisabled"
              :readonly="this.customerTelReadonly"
              :required="customerTelRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ customerMailaddressDefaultLabel }}</span>
              <span
                v-if="customerMailaddressRequired"
                class="badge badge-primary"
                >{{ $t("required") }}</span
              >
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerMailaddressFormValue"
              name="shipping_mail_address"
              :disabled="this.customerMailaddressDisabled"
              :readonly="this.customerMailaddressReadonly"
              :required="customerMailaddressRequired"
              class="form-control"
            />
            <label class="col-form-label">
              <span>{{ customerMailaddress2DefaultLabel }}</span>
              <span
                v-if="customerMailaddress2Required"
                class="badge badge-primary"
                >{{ $t("required") }}</span
              >
              <span v-else class="badge badge-secondary">{{
                $t("optional")
              }}</span>
            </label>
            <input
              type="text"
              :value="customerMailaddress2FormValue"
              name="shipping_mail_address2"
              :disabled="this.customerMailaddress2Disabled"
              :readonly="this.customerMailaddress2Readonly"
              :required="customerMailaddress2Required"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Token from "./token";

export default {
  props: {
    customerDefaultValue: {
      type: String,
      default: "customer_name",
    },
    dealerDefaultValue: {
      type: String,
      default: "name",
    },
    customerRequired: {
      type: Boolean,
      default: true,
    },
    customerZipRequired: {
      type: Boolean,
      default: true,
    },
    customerAdd1Required: {
      type: Boolean,
      default: true,
    },
    customerAdd2Required: {
      type: Boolean,
      default: false,
    },
    customerTelRequired: {
      type: Boolean,
      default: true,
    },
    customerMailaddressRequired: {
      type: Boolean,
      default: false,
    },
    customerMailaddress2Required: {
      type: Boolean,
      default: false,
    },
    customertNameRequired: {
      type: Boolean,
      default: false,
    },
    customerTitle: {
      type: String,
      default: "",
    },
    salesTitle: {
      type: String,
      default: "",
    },
    customerUrl: {
      type: String,
      default:
        "https://id.kentem.com" + "/api/v1/kentem_information/data_table",
    },
    dealerDefaultLabelName: {
      type: String,
      default: "name",
    },
    dealerIdName: {
      type: String,
      default: "kentem_id",
    },
    dealerLabel: {
      type: String,
      default: "",
    },
    dealerLabelKey: {
      type: String,
      default: "name",
    },
    dealerName: {
      type: String,
      default: "sales_kentem_id",
    },
    dealerRequired: {
      type: Boolean,
      default: true,
    },
    dealerTitle: {
      type: String,
      default: "",
    },
    dealerUrl: {
      type: String,
      default: "https://id.kentem.com" + "/api/v1/kentem_sales/data_table",
    },
    estimateOrder: {
      type: Object,
      default: () => {},
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    kentemId: {
      type: String,
      default: null,
    },
    referenceDefaultLabelName: {
      type: String,
      default: "reference_number",
    },
    referenceDefaultValue: {
      type: [String, Number],
      default: "",
    },
    referenceIdName: {
      type: String,
      default: "reference_number",
    },
    referenceLabel: {
      type: String,
      default: "",
    },
    referenceLabelKey: {
      type: String,
      default: "reference_number",
    },
    referenceName: {
      type: String,
      default: "reference_number",
    },
    referenceRequired: {
      type: Boolean,
      default: true,
    },
    referenceTitle: {
      type: String,
      default: "",
    },
    sendtoRequired: {
      type: Boolean,
      default: true,
    },
    salessegmentRequired: {
      type: Boolean,
      default: true,
    },
    sendtoDefault: {
      type: String,
      default: document.getElementById("sendto"),
    },
    salessegmentDefault: {
      type: String,
      default: document.getElementById("salessegment"),
    },
    contactName: {
      type: String,
      default: "contact_name",
    },
    salesKentemId: {
      type: String,
      default: null,
    },
    hasNewCloudProduct: {
      type: Number,
      default: null,
    },
    apiToken: {
      type: String,
      required: true,
    },
    isNonUsersOrder: {
      type: Number,
      default: "is_non_users_order",
    },
    isConfirmBack: {
      type: Number,
      default: 0,
    },
    oldShippingCompany: {
      type: String,
      default: "",
    },
    oldShippingShokonCode: {
      type: String,
      default: "",
    },
    oldShippingZip: {
      type: String,
      default: "",
    },
    oldShippingAddressLine: {
      type: String,
      default: "",
    },
    oldShippingAddressLineSecond: {
      type: String,
      default: "",
    },
    oldShippingContactName: {
      type: String,
      default: "",
    },
    oldShippingTel: {
      type: String,
      default: "",
    },
    oldShippingCloudMail: {
      type: String,
      default: "",
    },
    oldShippingDekisukuMail: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bearer: "",
      customerDefaultLabel: this.$i18n.translate("会社名"),
      customerZipDefaultLabel: this.$i18n.translate("郵便番号"),
      customertNameDefaultLabel: this.$i18n.translate("担当者名"),
      customerAdd1DefaultLabel: this.$i18n.translate("住所1"),
      customerAdd2DefaultLabel: this.$i18n.translate("住所2"),
      customerTelDefaultLabel: this.$i18n.translate("電話番号"),
      customerMailaddressDefaultLabel: this.$i18n.translate(
        "メールアドレス 【クラウド新規・ナビ枝番追加・遠隔臨場の場合は、「登録完了のお知らせ」のご連絡先をご記入下さい】"
      ),
      customerMailaddress2DefaultLabel: this.$i18n.translate(
        "メールアドレス 【デキスク新規の場合は、管理者となる方のアドレスをご記入下さい。】"
      ),
      customerDefaultTitle: this.$i18n.translate("顧客情報一覧"),
      salesDefaultTitle:
        this.$i18n.translate("販売店に紐づく2次店や営業所一覧"),
      customerDialog: false,
      salesDialog: false,
      customerFormValue: this.customerDefaultValue,
      customerZipFormValue: "",
      customertNameFormValue: "",
      customerAdd1FormValue: "",
      customerAdd2FormValue: "",
      customerTelFormValue: "",
      customerMailaddressFormValue: "",
      customerMailaddress2FormValue: "",
      customerShokonCodeFormValue: "",
      customerHeaders: [],
      customerIdName: "kentem_id",
      customerItems: [],
      customerLabelData: "",
      customerZipLabelData: "",
      customerLabelKey: "name",
      customerLabelName: "name",
      customerLabelValue: "",
      customerLoading: true,
      salesLoading: true,
      customerTitleData: "",
      salesTitleData: "",
      dealerDefaultLabel: this.$i18n.translate("販売店"),
      dealerDefaultTitle: this.$i18n.translate("販売店情報一覧"),
      dealerDialog: false,
      dealerFormValue: this.dealerDefaultValue,
      dealerHeaders: [],
      dealerItems: [],
      dealerLabelData: "",
      dealerLabelName: this.dealerDefaultLabelName,
      dealerLabelValue: "",
      dealerLoading: true,
      dealerSearch: "",
      dealerTitleData: "",
      referenceDefaultLabel: this.$i18n.translate("Kentem整理番号"),
      referenceDefaultTitle: this.$i18n.translate("整理番号一覧"),
      referenceDialog: false,
      referenceFormValue: this.referenceDefaultValue,
      referenceHeaders: [],
      referenceItems: [],
      shippingItems: [],
      referenceLabelData: "",
      referenceLabelName: this.referenceDefaultLabelName,
      referenceLabelValue: "",
      referenceLoading: true,
      referenceSearch: "",
      referenceTitleData: "",
      loadingLabel: this.$i18n.translate("loading"),
      searchLabel: this.$i18n.translate("search"),
      selected: "",
      sendtoValue: this.sendtoDefault,
      selessegmentValue: this.salessegmentDefault,
      tokenUrl: "/api/v1/token",
      temp_shokon_code: "",
      linkDealerHeaders: [],
      linkDealerItems: [],
      linkDealerLoading: true,
      customerReadonly: true,
      customerDisabled: true,
      customerZipDisabled: false,
      customerZipReadonly: false,
      customerAdd1Readonly: false,
      customerAdd1Disabled: false,
      customerAdd1Readonly: false,
      customerAdd2Disabled: false,
      customerAdd2Readonly: false,
      customertNameDisabled: false,
      customertNameReadonly: false,
      customerTelDisabled: false,
      customerTelReadonly: false,
      customerMailaddressDisabled: false,
      customerMailaddressReadonly: false,
      customerMailaddress2Disabled: false,
      customerMailaddress2Readonly: false,
    };
  },
  created: function () {
    this.customerLabelValue =
      this.oldShippingCompany !== null && this.oldShippingCompany !== undefined
        ? this.oldShippingCompany
        : "";
    this.customerFormValue =
      this.estimateOrder.shipping_company !== null &&
      this.estimateOrder.shipping_company !== undefined
        ? this.estimateOrder.shipping_company
        : "";
    this.customerShokonCodeFormValue =
      this.estimateOrder.shipping_shokon_code !== null &&
      this.estimateOrder.shipping_shokon_code !== undefined
        ? this.estimateOrder.shipping_shokon_code
        : "";
    this.customerZipFormValue =
      this.estimateOrder.shipping_zip !== null &&
      this.estimateOrder.shipping_zip !== undefined
        ? this.estimateOrder.shipping_zip
        : "";
    this.customerAdd1FormValue =
      this.estimateOrder.shipping_address1 !== null &&
      this.estimateOrder.shipping_address1 !== undefined
        ? this.estimateOrder.shipping_address1
        : "";
    this.customerAdd2FormValue =
      this.estimateOrder.shipping_address2 !== null &&
      this.estimateOrder.shipping_address2 !== undefined
        ? this.estimateOrder.shipping_address2
        : "";
    this.customertNameFormValue =
      this.estimateOrder.shipping_contact_name !== null &&
      this.estimateOrder.shipping_contact_name !== undefined
        ? this.estimateOrder.shipping_contact_name
        : "";
    this.customerTelFormValue =
      this.estimateOrder.shipping_tel !== null &&
      this.estimateOrder.shipping_tel !== undefined
        ? this.estimateOrder.shipping_tel
        : "";
    this.customerMailaddressFormValue =
      this.estimateOrder.shipping_mail_address !== null &&
      this.estimateOrder.shipping_mail_address !== undefined
        ? this.estimateOrder.shipping_mail_address
        : "";
    this.customerMailaddress2FormValue =
      this.estimateOrder.shipping_mail_address2 !== null &&
      this.estimateOrder.shipping_mail_address2 !== undefined
        ? this.estimateOrder.shipping_mail_address2
        : "";

    if (this.isConfirmBack == 1) {
      this.customerLabelValue =
        this.oldShippingCompany !== null &&
        this.oldShippingCompany !== undefined
          ? this.oldShippingCompany
          : "";
      this.customerFormValue =
        this.oldShippingCompany !== null &&
        this.oldShippingCompany !== undefined
          ? this.oldShippingCompany
          : "";
      this.customerShokonCodeFormValue =
        this.oldShippingShokonCode !== null &&
        this.oldShippingShokonCode !== undefined
          ? this.oldShippingShokonCode
          : "";
      this.customerZipFormValue =
        this.oldShippingZip !== null && this.oldShippingZip !== undefined
          ? this.oldShippingZip
          : "";
      this.customerAdd1FormValue =
        this.oldShippingAddressLine !== null &&
        this.oldShippingAddressLine !== undefined
          ? this.oldShippingAddressLine
          : "";
      this.customerAdd2FormValue =
        this.oldShippingAddressLineSecond !== null &&
        this.oldShippingAddressLineSecond !== undefined
          ? this.oldShippingAddressLineSecond
          : "";
      this.customertNameFormValue =
        this.oldShippingContactName !== null &&
        this.oldShippingContactName !== undefined
          ? this.oldShippingContactName
          : "";
      this.customerTelFormValue =
        this.oldShippingTel !== null && this.oldShippingTel !== undefined
          ? this.oldShippingTel
          : "";
      this.customerMailaddressFormValue =
        this.oldShippingCloudMail !== null &&
        this.oldShippingCloudMail !== undefined
          ? this.oldShippingCloudMail
          : "";
      this.customerMailaddress2FormValue =
        this.oldShippingDekisukuMail !== null &&
        this.oldShippingDekisukuMail !== undefined
          ? this.oldShippingDekisukuMail
          : "";
    }

    this.dealerLabelName = this.dealerDefaultLabelName ?? this.dealerIdName;
    this.referenceLabelName =
      this.referenceDefaultLabelName ?? this.referenceIdName;

    this.customerLabelData = this.customerDefaultLabel;
    this.customerZipLabelData = this.customerZipDefaultLabel;
    if (this.customerTitle === "") {
      this.customerTitleData = this.customerDefaultTitle;
    }
    if (this.salesTitle === "") {
      this.salesTitleData = this.salesDefaultTitle;
    }
    if (this.dealerLabel === "") {
      this.dealerLabelData = this.dealerDefaultLabel;
    }
    if (this.dealerTitle === "") {
      this.dealerTitleData = this.dealerDefaultTitle;
    }
    if (this.referenceLabel === "") {
      this.referenceLabelData = this.referenceDefaultLabel;
    }
    if (this.referenceTitle === "") {
      this.referenceTitleData = this.referenceDefaultTitle;
    }
    this.getTokenAndLoadList();
  },
  methods: {
    /**
     *
     */
    async loadList() {
      this.customerLoading = true;
      this.salesLoading = true;
      if (this.customerFormValue !== "") {
        this.customerLabelValue = this.$i18n.translate("loading");
      }

      const data = await this.getData();
      if (data.customer) {
        this.customerHeaders = data.customer.headers;
        this.customerItems = data.customer.items;
      }

      if (data.dealer) {
        this.linkDealerHeaders = data.dealer.headers;
        this.linkDealerItems = data.dealer.items;
      }

      if (this.customerShokonCodeFormValue !== "") {
        const defaultItem = this.customerItems.find(
          (row) => row["shokon_code"] === this.customerShokonCodeFormValue
        );
        this.customerLabelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.customerLabelKey]
            : this.$i18n.translate("unknown");
      }
      if (this.dealerFormValue !== "" && this.dealerLabelKey !== "") {
        const defaultItem = this.dealerItems.find(
          (row) => row[this.dealerIdName] === this.dealerFormValue
        );
        this.dealerLabelValue =
          typeof defaultItem !== "undefined"
            ? defaultItem[this.dealerLabelKey]
            : this.$i18n.translate("unknown");
      }

      //クラウドの任意・必須を設定
      this.convertHasNewCloudProduct(this.hasNewCloudProduct);

      if (this.isConfirmBack == 0) {
        if (this.isNonUsersOrder == 0) {
          if (
            this.kentemId !== "" &&
            this.customerDefaultValue !== "" &&
            Object.keys(this.estimateOrder).length == 0
          ) {
            this.customerFormValue = this.customerDefaultValue;
            this.customerLabelValue = this.customerDefaultValue;
            this.getShokonCodeByKentemId(this.kentemId);
            this.customerDialog = false;
            this.salesDialog = false;
          }
        } else {
          if (
            this.salesKentemId !== "" &&
            this.dealerDefaultValue !== "" &&
            Object.keys(this.estimateOrder).length == 0
          ) {
            this.customerFormValue = this.dealerDefaultValue;
            this.customerLabelValue = this.dealerDefaultValue;
            this.getShokonCodeByKentemId(this.salesKentemId);
            this.customerDialog = false;
            this.salesDialog = false;
          }
        }
      }

      //汎用売上の場合は会社名の入力欄は活性
      if (this.salesKentemId == "P1241095") {
        this.customerReadonly = false;
        this.customerDisabled = false;
      }

      this.customerLoading = false;
      this.salesLoading = false;
      this.referenceLoading = false;
    },
    /**
     *
     * @return {Promise<void | {customer: any;dealer: any;}>}
     */
    getData() {
      return Promise.all([
        Axios.get(this.customerUrl, {
          headers: {
            Authorization: this.bearer,
          },
        }),
        Axios.get(
          this.kentemIdUrl +
            `/api/v1/kentem_link_sales/data_table/${this.salesKentemId}`,
          {
            headers: {
              Authorization: this.bearer,
            },
          }
        ),
      ])
        .then(([customer, dealer]) => {
          const data = {};
          data.customer = customer.data;
          data.dealer = dealer.data;
          return Promise.resolve(data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     *
     * @param {String} code
     */
    setDealerKentemIdByCode(code) {
      Axios.get(
        this.kentemIdUrl + `/api/v1/kentem_information/shokon/${code}`,
        {
          headers: {
            Authorization: this.bearer,
          },
        }
      )
        .then((res) => {
          if (res.data) {
            this.dealerFormValue = res.data.data.kentem_id;
          } else {
            this.dealerLabelValue = this.$i18n.translate("unknown");
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getShokonCodeByKentemId(id) {
      Axios.get(this.kentemIdUrl + `/api/v1/kentem_information/${id}`, {
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            if (
              res.data.data.shokon_code == null ||
              res.data.data.shokon_code == ""
            ) {
              this.getKentemInfo(id);
              this.canEnterShippingInfo();
            } else {
              this.temp_shokon_code = res.data.data.shokon_code;
              this.getShippingInfo(res.data.data.shokon_code);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getShippingInfo(id) {
      Axios.get(this.kentemIdUrl + `/api/v1/user_information/shokon/${id}`, {
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.shippingItems = res.data.data;
            if (
              this.shippingItems["name1"] == null ||
              this.shippingItems["add1"] == null
            ) {
              this.getSalesInfo(id);
            } else {
              this.setShippingInfo();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getSalesInfo(id) {
      Axios.get(this.kentemIdUrl + `/api/v1/sale_information/shokon/${id}`, {
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.shippingItems = res.data.data;
            this.setShippingInfo();
          }
        })
        .catch((error) => {
          console.log(error);
          this.dealerLabelValue = this.$i18n.translate("unknown");
        });
    },
    /**
     *
     * @param {String} id
     */
    getKentemInfo(id) {
      Axios.get(`/api/v1/osc/kentem_info/${id}`, {
        headers: {
          Authorization: "Bearer " + this.apiToken,
        },
      })
        .then((res) => {
          if (res.data) {
            this.customerZipFormValue = res.data.PostalCode;
            this.customerAdd1FormValue = res.data.AddressLine1;
            this.customerTelFormValue = res.data.RawPhoneNumber;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     *
     * @param {Object} item
     */
    async selectCustomerItem(item) {
      this.customerFormValue = item[this.customerLabelName];
      this.customerLabelValue = item[this.customerLabelName];
      this.bearer = await Token(this.tokenUrl);
      this.getShokonCodeByKentemId(item[this.customerIdName]);
      this.customerDialog = false;
      this.salesDialog = false;
    },
    /**
     *
     */
    async setShippingInfo() {
      const name1 = this.shippingItems["name1"] || "";
      const name2 = this.shippingItems["name2"] || "";
      this.customerLabelValue =
        name2 && name2.trim() !== "" ? `${name1} ${name2}` : name1;
      this.customerFormValue = this.customerLabelValue;
      this.customerZipFormValue = this.shippingItems["zip"];
      if (this.salesKentemId == "P1196185") {
        this.customertNameFormValue = this.contactName;
      } else {
        this.customertNameFormValue = "ご担当者";
      }
      this.customerAdd1FormValue = this.shippingItems["add1"];
      this.customerAdd2FormValue = this.shippingItems["add2"];
      this.customerTelFormValue = this.shippingItems["tel"];
      this.customerShokonCodeFormValue = this.temp_shokon_code;
      this.canEnterShippingInfo();
    },
    /**
     *
     * @param {Number} num
     */
    async convertHasNewCloudProduct(num) {
      if (num == 1) {
        this.customerMailaddressRequired = true;
      } else {
        this.customerMailaddressRequired = false;
      }
    },
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
    /**
     *
     */
    async noCanEnterShippingInfo() {
      this.customerZipDisabled = true;
      this.customerZipReadonly = true;
      this.customerZipFormValue = "";
      this.customerAdd1Disabled = true;
      this.customerAdd1Readonly = true;
      this.customerAdd1FormValue = "";
      this.customerAdd2Disabled = true;
      this.customerAdd2Readonly = true;
      this.customerAdd2FormValue = "";
      this.customertNameDisabled = true;
      this.customertNameReadonly = true;
      this.customertNameFormValue = "";
      this.customerTelDisabled = true;
      this.customerTelReadonly = true;
      this.customerTelFormValue = "";
      this.customerMailaddressDisabled = true;
      this.customerMailaddressReadonly = true;
      this.customerMailaddress2Disabled = true;
      this.customerMailaddress2Readonly = true;
    },
    /**
     *
     */
    async canEnterShippingInfo() {
      this.customerZipDisabled = false;
      this.customerZipReadonly = false;
      this.customerAdd1Disabled = false;
      this.customerAdd1Readonly = false;
      this.customerAdd2Disabled = false;
      this.customerAdd2Readonly = false;
      this.customertNameDisabled = false;
      this.customertNameReadonly = false;
      this.customerTelDisabled = false;
      this.customerTelReadonly = false;
      this.customerMailaddressDisabled = false;
      this.customerMailaddressReadonly = false;
      this.customerMailaddress2Disabled = false;
      this.customerMailaddress2Readonly = false;
    },
  },
};
</script>
