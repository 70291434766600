<template>
  <div>
    <div v-if="alertInfoMessage" class="alert alert-success">
      <strong>{{ alertInfoMessage }}</strong>
    </div>
    <!--商品情報-->
    <v-card class="my-2">
      <v-toolbar flat color="default">
        <v-toolbar-title>
          <h2 class="col-xs-12 col-sm-4 col-md-4 mt-2 py-0">
            {{ $t("estimate.product_information") }}
          </h2>
        </v-toolbar-title>
      </v-toolbar>
      <v-tabs v-model="tab">
        <v-tab v-for="t in tabs" :key="t.name" :href="'#tab-' + t.name">
          {{ t.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <!--デキスパート情報-->
        <v-tab-item :value="'tab-' + $t('dekispart')">
          <v-card>
            <div class="col-xs-12 col-sm-6 col-md-6">
              <dekis-detail
                ref="dekispartDetail"
                :kentem-id="kentemId"
                :dekis-id="dekisId"
                :kentem-id-url="kentemIdUrl"
                @input="setDekisID"
              />
            </div>
          </v-card>
          <v-tabs v-model="tabDekispart">
            <v-tab :href="'#tab-dekispart-normal'"> 通常 </v-tab>
            <v-tab :href="'#tab-dekispart-version-up'">
              {{ $t("version up") }}
            </v-tab>
            <v-tab :href="'#tab-dekispart-maintenance'"> 保守 </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabDekispart">
            <product-tab
              :callback="registerEstimateProduct"
              :csrf="csrf"
              :errors="errors"
              :estimate-id="estimateId"
              :kentem-id="kentemId"
              :msg="msg"
              :tab-name="'tab-dekispart-normal'"
            >
              <ref-product-dekis-list
                ref="dekispartList"
                :url="
                  kentemIdUrl +
                  '/api/v1/kentem_product/data_table/add_product/A'
                "
                :ricohUrl="
                  kentemIdUrl +
                  '/api/v1/kentem_product/data_table/add_product/ricoh/A'
                "
                :sales-segment="salesSegment"
                :title="$t('product_information.list')"
                id-name="kentem_product_code"
                name="product_id"
                :required="true"
                label="商品"
                label-key="name"
                default-label-name="name"
                :use-auto-close="false"
                :dekisid="selectedDekispartId"
              />
            </product-tab>
            <product-tab-without-form
              :csrf="csrf"
              :errors="errors"
              :msg="msg"
              :tab-name="'tab-dekispart-version-up'"
            >
              <div class="alert alert-success">
                <strong>
                  {{ DekispartVersionUpAlertMessage }}
                </strong>
              </div>
              <tab-form
                :button-label="'登録'"
                :callback="updateVersionEstimateProduct"
                :csrf="csrf"
                :errors="errors"
                :estimate-id="estimateId"
                :kentem-id="kentemId"
                :msg="msg"
              />
            </product-tab-without-form>
            <product-tab-without-form
              :csrf="csrf"
              :errors="errors"
              :msg="msg"
              :tab-name="'tab-dekispart-maintenance'"
            >
              <tab-form
                :button-label="'保守差額'"
                :callback="updateMaintenanceDifferenceEstimateProduct"
                :csrf="csrf"
                :errors="errors"
                :estimate-id="estimateId"
                :kentem-id="kentemId"
                :msg="msg"
              />
              <tab-form
                :button-label="'保守(1年)'"
                :callback="registerDekispartMaintenanceOneYear"
                :csrf="csrf"
                :errors="errors"
                :estimate-id="estimateId"
                :kentem-id="kentemId"
                :msg="msg"
              />
              <tab-form
                :button-label="'保守(5年)'"
                :callback="registerDekispartMaintenanceFiveYear"
                :csrf="csrf"
                :errors="errors"
                :estimate-id="estimateId"
                :kentem-id="kentemId"
                :msg="msg"
              />
            </product-tab-without-form>
          </v-tabs-items>
        </v-tab-item>
        <!--イノサイト情報-->
        <v-tab-item :value="'tab-' + $t('innosite.innosite')">
          <v-card>
            <div class="col-xs-12 col-sm-6 col-md-6">
              <innosite-detail
                ref="innositeDetail"
                :kentem-id="kentemId"
                :innosite-id="innositeId"
                :kentem-id-url="kentemIdUrl"
              />
            </div>
          </v-card>
          <v-tabs v-model="tabInnosite">
            <v-tab :href="'#tab-innosite-normal'"> 通常 </v-tab>
            <v-tab :href="'#tab-innosite-maintenance'"> 保守 </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabInnosite">
            <product-tab
              :callback="registerEstimateProduct"
              :csrf="csrf"
              :errors="errors"
              :estimate-id="estimateId"
              :kentem-id="kentemId"
              :msg="msg"
              :tab-name="'tab-innosite-normal'"
            >
              <ref-product-list
                ref="innositeList"
                :url="
                  kentemIdUrl +
                  '/api/v1/kentem_product/data_table/add_product/D'
                "
                :ricohUrl="
                  kentemIdUrl +
                  '/api/v1/kentem_product/data_table/add_product/ricoh/D'
                "
                :sales-segment="salesSegment"
                :title="$t('product_information.list')"
                id-name="kentem_product_code"
                name="product_id"
                :required="true"
                label="商品"
                label-key="name"
                default-label-name="name"
              />
            </product-tab>
            <product-tab-without-form
              :csrf="csrf"
              :errors="errors"
              :msg="msg"
              :tab-name="'tab-innosite-maintenance'"
            >
              <div class="alert alert-success">
                <strong>
                  {{ InnositeMaintenanceAlertMessage }}
                </strong>
              </div>
              <tab-form
                :button-label="'保守差額'"
                :callback="updateMaintenanceDifferenceInnosite"
                :csrf="csrf"
                :errors="errors"
                :estimate-id="estimateId"
                :kentem-id="kentemId"
                :msg="msg"
              />
              <tab-form
                :button-label="'保守(1年)'"
                :callback="registerInnositeMaintenanceOneYear"
                :csrf="csrf"
                :errors="errors"
                :estimate-id="estimateId"
                :kentem-id="kentemId"
                :msg="msg"
              />
              <tab-form
                :button-label="'保守(5年)'"
                :callback="registerInnositeMaintenanceFiveYear"
                :csrf="csrf"
                :errors="errors"
                :estimate-id="estimateId"
                :kentem-id="kentemId"
                :msg="msg"
              />
            </product-tab-without-form>
          </v-tabs-items>
        </v-tab-item>
        <!--クラウド情報-->
        <v-tab-item :value="'tab-' + $t('cloud.cloud')">
          <v-card>
            <div class="col-xs-12 col-sm-6 col-md-6">
              <cloud-detail
                ref="cloudDetail"
                :kentem-id="kentemId"
                :cloud-id="cloudId"
                :kentem-id-url="kentemIdUrl"
                :callback-on-change="onChangeCloudDetail"
              />
            </div>
          </v-card>
          <v-tabs v-model="tabCloud">
            <v-tab :href="'#tab-cloud-general'"> 全て </v-tab>
            <v-tab :href="'#tab-cloud-without-kaisoku'">
              クラウド(ナビ以外)
            </v-tab>
            <v-tab :href="'#tab-cloud-kaisoku'"> 快測ナビ </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabCloud">
            <v-tab-item :value="'tab-cloud-general'">
              <tab-card
                :button-label="'Register'"
                :callback="registerEstimateProduct"
                :csrf="csrf"
                :errors="errors"
                :estimate-id="estimateId"
                :kentem-id="kentemId"
                :msg="msg"
              >
                <ref-product-cloud-list
                  ref="cloudList"
                  :url="
                    kentemIdUrl +
                    '/api/v1/kentem_product/data_table/add_product/E'
                  "
                  :ricohUrl="
                    kentemIdUrl +
                    '/api/v1/kentem_product/data_table/add_product/ricoh/E'
                  "
                  :sales-segment="salesSegment"
                  :title="$t('product_information.list')"
                  id-name="kentem_product_code"
                  name="product_id"
                  :required="true"
                  label="商品"
                  label-key="name"
                  default-label-name="name"
                />
              </tab-card>
            </v-tab-item>
            <v-tab-item :value="'tab-cloud-without-kaisoku'">
              <v-tabs v-model="tabCloudWithoutKaisoku">
                <v-tab :href="'#tab-cloud-without-kaisoku-update'">
                  更新
                </v-tab>
                <v-tab :href="'#tab-cloud-without-kaisoku-add'">
                  追加(残月自動)
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabCloudWithoutKaisoku">
                <product-tab
                  :button-label="'更新(1年)'"
                  :callback="updateCloudProduct"
                  :csrf="csrf"
                  :errors="errors"
                  :estimate-id="estimateId"
                  :kentem-id="kentemId"
                  :msg="msg"
                  :tab-name="'tab-cloud-without-kaisoku-update'"
                >
                  <div class="alert alert-success">
                    <strong>
                      {{ FildnetUpdateAlertMessage }}
                    </strong>
                  </div>
                </product-tab>
                <product-tab
                  :button-label="'追加'"
                  :callback="addCloudProduct"
                  :csrf="csrf"
                  :errors="errors"
                  :estimate-id="estimateId"
                  :kentem-id="kentemId"
                  :msg="msg"
                  :tab-name="'tab-cloud-without-kaisoku-add'"
                >
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <div class="form-group">
                      <div class="alert alert-success">
                        <strong>
                          {{ CloudRemaingMonthAddAlertMessage }}<br />
                          {{ FildnetAddAlertMessage }}
                        </strong>
                      </div>
                      <label
                        for="cloud_product_type_cloud_add"
                        class="col-form-label"
                      >
                        <span>クラウド商品種別</span>
                        <span class="badge badge-primary">必須</span>
                      </label>
                      <select
                        id="cloud_product_type_cloud_add"
                        name="cloud_product_type"
                        required="required"
                        class="form-control"
                      >
                        <option value="" selected="selected">
                          選択してください
                        </option>
                        <option value="8">KSデータバンク</option>
                        <option value="9">SiteBox</option>
                        <option value="11">SiteBoxトンネル</option>
                        <option value="13">写管屋クラウド</option>
                        <option value="14">配筋検査</option>
                        <option value="19">出来形管理クラウド</option>
                        <option value="22">快測Scan</option>
                        <option value="27">品質管理クラウド</option>
                        <option value="30">施工体制クラウド</option>
                        <option value="31">快測AR</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <div class="form-group">
                      <label for="quantity_cloud_add" class="col-form-label">
                        <span>数量</span>
                        <span class="badge badge-primary">必須</span><br />
                        <small>
                          <span>※KSデータバンクの場合はGB<wbr /></span><br />
                          <span>※おまとめパックは5個単位<wbr /></span>
                        </small>
                      </label>
                      <input
                        id="quantity_cloud_add"
                        type="number"
                        name="quantity"
                        required="required"
                        min="1"
                        step="1"
                        class="form-control"
                      />
                    </div>
                  </div>
                </product-tab>
              </v-tabs-items>
            </v-tab-item>
            <v-tab-item :value="'tab-cloud-kaisoku'">
              <v-tabs v-model="tabCloudKaisoku">
                <v-tab :href="'#tab-cloud-kaisoku-std-to-adv'"> Std→Adv </v-tab>
                <v-tab :href="'#tab-cloud-kaisoku-update'"> 更新</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabCloudKaisoku">
                <product-tab
                  :button-label="'切り替え'"
                  :callback="switchStdToAdv"
                  :csrf="csrf"
                  :errors="errors"
                  :estimate-id="estimateId"
                  :kentem-id="kentemId"
                  :msg="msg"
                  :tab-name="'tab-cloud-kaisoku-std-to-adv'"
                >
                  <select-kaisokustd
                    id="management_sub_code_kaisoku_switch"
                    ref="selectKaisokustd"
                    :callback-created="updateSelectKaisokustdCloudId"
                    :kentem-id-url="kentemIdUrl"
                    name="management_sub_code"
                  />
                </product-tab>
                <product-tab
                  :button-label="'更新(1年)'"
                  :callback="updateKaisokuNaviProductOneYear"
                  :csrf="csrf"
                  :errors="errors"
                  :estimate-id="estimateId"
                  :kentem-id="kentemId"
                  :msg="msg"
                  :tab-name="'tab-cloud-kaisoku-update'"
                >
                  <select-kaisoku
                    id="management_sub_code_kaisoku_switch"
                    ref="selectKaisokustd"
                    :callback-created="updateSelectKaisokustdCloudId"
                    :kentem-id-url="kentemIdUrl"
                    name="management_sub_code"
                  />
                </product-tab>
                <product-tab
                  :button-label="'更新(5年)'"
                  :callback="updateKaisokuNaviProductFiveYear"
                  :csrf="csrf"
                  :errors="errors"
                  :estimate-id="estimateId"
                  :kentem-id="kentemId"
                  :msg="msg"
                  :tab-name="'tab-cloud-kaisoku-update'"
                >
                </product-tab>
              </v-tabs-items>
            </v-tab-item>
          </v-tabs-items>
        </v-tab-item>
        <!--PRODOUGU情報-->
        <product-tab
          :callback="registerEstimateProduct"
          :csrf="csrf"
          :errors="errors"
          :estimate-id="estimateId"
          :kentem-id="kentemId"
          :msg="msg"
          :tab-name="'tab-' + $t('prodougu')"
        >
          <prodougu-detail
            :kentem-id="kentemId"
            :prodougu-id="prodouguId"
            :kentem-id-url="kentemIdUrl"
          />
          <ref-product-list
            ref="prodouguList"
            :url="
              kentemIdUrl + '/api/v1/kentem_product/data_table/add_product/H'
            "
            :ricohUrl="
              kentemIdUrl +
              '/api/v1/kentem_product/data_table/add_product/ricoh/H'
            "
            :sales-segment="salesSegment"
            :title="$t('product_information.list')"
            id-name="kentem_product_code"
            name="product_id"
            :required="true"
            label="商品"
            label-key="name"
            default-label-name="name"
          />
        </product-tab>
        <!--デキスク情報-->
        <product-tab
          :callback="registerEstimateProduct"
          :csrf="csrf"
          :errors="errors"
          :estimate-id="estimateId"
          :kentem-id="kentemId"
          :msg="msg"
          :tab-name="'tab-' + $t('dekisku')"
        >
          <dekisku-detail
            :kentem-id="kentemId"
            :dekisku-id="dekiskuId"
            :kentem-id-url="kentemIdUrl"
          />
          <ref-product-list
            ref="dekispartSchoolList"
            :url="
              kentemIdUrl + '/api/v1/kentem_product/data_table/add_product/B'
            "
            :ricohUrl="
              kentemIdUrl +
              '/api/v1/kentem_product/data_table/add_product/ricoh/B'
            "
            :sales-segment="salesSegment"
            :title="$t('product_information.list')"
            id-name="kentem_product_code"
            name="product_id"
            :required="true"
            label="商品"
            label-key="name"
            default-label-name="name"
          />
        </product-tab>
        <!--工事情報共有システム情報-->
        <v-tab-item :value="'tab-' + $t('kjk')">
          <v-tabs v-model="tabConstructionResult">
            <v-tab :href="'#tab-kjk-normal'"> 通常 </v-tab>
            <v-tab :href="'#tab-kjk-auto-estimate'"> 簡単見積 </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabConstructionResult">
            <product-tab
              disabled
              :callback="registerEstimateProduct"
              :csrf="csrf"
              :errors="errors"
              :estimate-id="estimateId"
              :kentem-id="kentemId"
              :msg="msg"
              :tab-name="'tab-kjk-normal'"
            >
              <ref-product-list
                ref="constructionInformationSystemList"
                :url="
                  kentemIdUrl +
                  '/api/v1/kentem_product/data_table/add_product/F'
                "
                :ricohUrl="
                  kentemIdUrl +
                  '/api/v1/kentem_product/data_table/add_product/ricoh/F'
                "
                :sales-segment="salesSegment"
                :title="$t('product_information.list')"
                id-name="kentem_product_code"
                name="product_id"
                :required="true"
                label="商品"
                label-key="name"
                default-label-name="name"
              />
            </product-tab>
            <product-tab
              :button-label="'見積作成'"
              :callback="openDialog"
              :csrf="csrf"
              :errors="errors"
              :estimate-id="estimateId"
              :kentem-id="kentemId"
              :msg="msg"
              :tab-name="'tab-kjk-auto-estimate'"
            >
              <div class="col-xs-12 col-sm-6 col-md-6">
                <label class="col-form-label">
                  <span>案件コード</span>
                  <span class="badge badge-primary">必須</span><br />
                </label>
                <input
                  type="text"
                  name="case_code"
                  required="required"
                  class="form-control"
                  v-model="caseCode"
                />
              </div>
              <v-dialog v-model="dialog" persistent max-width="1000">
                <v-card>
                  <v-card-title></v-card-title>
                  <v-card-text>
                    以下の内容で処理を実行しますか？<br /><br />
                    <div v-html="confirmationMessageWithLineBreaks"></div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" @click="confirmAction">はい</v-btn>
                    <v-btn color="red" @click="closeDialog">いいえ</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </product-tab>
          </v-tabs-items>
        </v-tab-item>
        <!--TsunaguWeb-->
        <product-tab
          disabled
          :callback="registerEstimateProduct"
          :csrf="csrf"
          :errors="errors"
          :estimate-id="estimateId"
          :kentem-id="kentemId"
          :msg="msg"
          :tab-name="'tab-' + $t('tsunaguweb')"
        >
          <ref-product-list
            ref="tsunaguWebList"
            :url="
              kentemIdUrl + '/api/v1/kentem_product/data_table/add_product/G'
            "
            :ricohUrl="
              kentemIdUrl +
              '/api/v1/kentem_product/data_table/add_product/ricoh/G'
            "
            :sales-segment="salesSegment"
            :title="$t('product_information.list')"
            id-name="kentem_product_code"
            name="product_id"
            :required="true"
            label="商品"
            label-key="name"
            default-label-name="name"
          />
        </product-tab>
        <!--KentemTech-->
        <product-tab
          disabled
          :callback="registerEstimateProduct"
          :csrf="csrf"
          :errors="errors"
          :estimate-id="estimateId"
          :kentem-id="kentemId"
          :msg="msg"
          :tab-name="'tab-' + $t('kentemtech')"
        >
          <ref-product-kentemtech-list
            ref="kentemTechList"
            :url="
              kentemIdUrl + '/api/v1/kentem_product/data_table/add_product/I'
            "
            :ricohUrl="
              kentemIdUrl +
              '/api/v1/kentem_product/data_table/add_product/ricoh/I'
            "
            :sales-segment="salesSegment"
            :title="$t('product_information.list')"
            id-name="kentem_product_code"
            name="product_id"
            :required="true"
            label="商品"
            label-key="name"
            default-label-name="name"
          />
        </product-tab>
        <!--KENTEM ACADEMY-->
        <product-tab
          disabled
          :callback="registerEstimateProduct"
          :csrf="csrf"
          :errors="errors"
          :estimate-id="estimateId"
          :kentem-id="kentemId"
          :msg="msg"
          :tab-name="'tab-' + $t('kentem_academy')"
        >
          <ref-product-kentem-academy-list
            ref="kentemAcademyList"
            :url="
              kentemIdUrl + '/api/v1/kentem_product/data_table/add_product/L'
            "
            :ricohUrl="
              kentemIdUrl +
              '/api/v1/kentem_product/data_table/add_product/ricoh/L'
            "
            :sales-segment="salesSegment"
            :title="$t('product_information.list')"
            id-name="kentem_product_code"
            name="product_id"
            :required="true"
            label="商品"
            label-key="name"
            default-label-name="name"
          />
        </product-tab>
        <!--クロスゼロ-->
        <product-tab
          disabled
          :callback="registerEstimateProduct"
          :csrf="csrf"
          :errors="errors"
          :estimate-id="estimateId"
          :kentem-id="kentemId"
          :msg="msg"
          :tab-name="'tab-' + $t('xzero')"
        >
          <ref-product-list
            ref="xzeroList"
            :url="
              kentemIdUrl + '/api/v1/kentem_product/data_table/add_product/J'
            "
            :ricohUrl="
              kentemIdUrl +
              '/api/v1/kentem_product/data_table/add_product/ricoh/J'
            "
            :sales-segment="salesSegment"
            :title="$t('product_information.list')"
            id-name="kentem_product_code"
            name="product_id"
            :required="true"
            label="商品"
            label-key="name"
            default-label-name="name"
          />
        </product-tab>
        <!--貸出・デモ版-->
        <product-tab
          disabled
          :callback="registerEstimateProduct"
          :csrf="csrf"
          :errors="errors"
          :estimate-id="estimateId"
          :kentem-id="kentemId"
          :msg="msg"
          :tab-name="'tab-' + $t('lending_demo')"
        >
          <ref-product-list
            ref="lendingDemoList"
            :url="
              kentemIdUrl + '/api/v1/kentem_product/data_table/add_product/K'
            "
            :ricohUrl="
              kentemIdUrl +
              '/api/v1/kentem_product/data_table/add_product/ricoh/K'
            "
            :sales-segment="salesSegment"
            :title="$t('product_information.list')"
            id-name="kentem_product_code"
            name="product_id"
            :required="true"
            label="商品"
            label-key="name"
            default-label-name="name"
          />
        </product-tab>
        <!--その他-->
        <product-tab
          :callback="registerEstimateProduct"
          :csrf="csrf"
          :errors="errors"
          :estimate-id="estimateId"
          :kentem-id="kentemId"
          :msg="msg"
          :tab-name="'tab-' + $t('other')"
        >
          <other-detail
            :kentem-id="kentemId"
            :all-id="allId"
            :kentem-id-url="kentemIdUrl"
          />
          <ref-product-list
            ref="otherProductList"
            :url="
              kentemIdUrl + '/api/v1/kentem_product/data_table/add_product/Y'
            "
            :ricohUrl="
              kentemIdUrl +
              '/api/v1/kentem_product/data_table/add_product/ricoh/Y'
            "
            :sales-segment="salesSegment"
            :title="$t('product_information.list')"
            id-name="kentem_product_code"
            name="product_id"
            :required="true"
            label="商品"
            label-key="name"
            default-label-name="name"
          />
        </product-tab>
        <v-tab-item disabled :value="'tab-' + $t('kjk_price')">
          <v-card flat>
            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th v-for="h in priceHeaders" :key="h.text" scope="col">
                      {{ h.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="i in priceItems" :key="i.id">
                    <td v-for="d in i" :key="d">
                      {{ d }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-progress-circular
      v-if="connecting"
      :indeterminate="progress === 0"
      :value="progress"
      color="light-blue"
    />
    <v-card>
      <v-card-text>
        <div class="row col-xs-12 col-sm-12 col-md-6 py-0 table-responsive">
          <table class="table table-striped">
            <tr>
              <th>{{ $t("estimate.total_amount") }}</th>
              <td>{{ totalAmountPrice | addComma }}</td>
            </tr>
          </table>
        </div>
        <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header>一括変更</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <v-row align-content="center">
                  <v-col cols="auto" class="mr-auto">
                    <v-switch
                      v-model="isBulkUpdatePartitionRate"
                      :label="`仕切率一括変更モード: ${
                        isBulkUpdatePartitionRate ? 'ON' : 'OFF'
                      }`"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdatePartitionRate"
                      color="secondary"
                      @click="changeStatusPartitionRateTarget(false)"
                      >全選択解除</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdatePartitionRate"
                      color="secondary"
                      @click="changeStatusPartitionRateTarget(true)"
                      >全選択</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <label
                      v-if="isBulkUpdatePartitionRate"
                      for="bulcPartitionRate"
                      >仕切率(%)
                    </label>
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <input
                      v-if="isBulkUpdatePartitionRate"
                      v-model="bulkPartitionRate"
                      type="number"
                      max="100"
                      min="0"
                      size="3"
                      name="balkPertitionRate"
                      id="balkPertitionRate"
                      class="form-control"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isBulkUpdatePartitionRate"
                      type="button"
                      icon
                      class="bg-gradient-primary shadow"
                      @click="bulkUpdatePartitionRate()"
                    >
                      <i class="fas fa-save" />
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="auto" class="mr-auto">
                    <v-switch
                      v-model="isSelectiveDeletion"
                      :label="`削除モード: ${
                        isSelectiveDeletion ? '選択' : '個別'
                      }`"
                    />
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isSelectiveDeletion"
                      color="secondary"
                      @click="changeStatusDeleteTarget(false)"
                      >全選択解除</v-btn
                    >
                  </v-col>
                  <v-col align-self="center" cols="auto">
                    <v-btn
                      v-if="isSelectiveDeletion"
                      color="secondary"
                      @click="changeStatusDeleteTarget(true)"
                      >全選択</v-btn
                    >
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      v-if="isSelectiveDeletion"
                      type="button"
                      icon
                      class="bg-gradient-danger shadow"
                      @click="deleteSelectedItems()"
                    >
                      <i class="far fa-trash-alt" />
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <product-editable
          :csrf="csrf"
          :token="token"
          :headers="productHeaders"
          :is-bulk-update-partition-rate="isBulkUpdatePartitionRate"
          :is-selective-deletion="isSelectiveDeletion"
          :items="productItems"
          :estimate-id="this.estimateId"
          ref="productEditable"
          @total-amount="onUpdateTotalAmountPrice"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Axios from "axios";

import ProductTab from "./estimateproduct/product-tab.vue";
import ProductTabWithoutForm from "./estimateproduct/product-tab-without-form.vue";
import TabCard from "./estimateproduct/tab-card.vue";
import TabForm from "./estimateproduct/tab-form.vue";
import SelectKaisokustd from "./estimateproduct/select-kaisokustd.vue";
import SelectKaisoku from "./estimateproduct/select-kaisoku.vue";

export default {
  components: {
    ProductTab,
    ProductTabWithoutForm,
    SelectKaisokustd,
    SelectKaisoku,
    TabCard,
    TabForm,
  },
  filters: {
    addComma: (val) => {
      return val.toLocaleString();
    },
  },
  props: {
    alertInfo: {
      type: String,
      default: "",
    },
    allId: {
      type: Array,
      required: true,
    },
    cloudId: {
      type: Array,
      required: true,
    },
    constructionId: {
      type: Array,
      required: true,
    },
    csrf: {
      type: String,
      required: true,
    },
    dekisId: {
      type: Array,
      required: true,
    },
    dekiskuId: {
      type: Array,
      required: true,
    },
    kjkId: {
      type: Array,
      required: true,
    },
    tsunaguId: {
      type: Array,
      required: true,
    },
    prodouguId: {
      type: Array,
      required: true,
    },
    kentemTechId: {
      type: Array,
      required: true,
    },
    xzeroId: {
      type: Array,
      required: true,
    },
    lendingDemoId: {
      type: Array,
      required: true,
    },
    kentemAcademyId: {
      type: Array,
      required: true,
    },
    estimateId: {
      type: Number,
      default: null,
    },
    salesSegment: {
      type: Number,
      default: null,
    },
    errors: {
      type: Object,
      default: () => {},
    },
    headers: {
      type: Array,
      required: true,
    },
    innositeId: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    kentemId: {
      type: String,
      required: true,
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    msg: {
      type: String,
      default: null,
    },
    priceHeaders: {
      type: Array,
      default: () => [],
    },
    priceItems: {
      type: Array,
      default: () => [],
    },
    token: {
      type: String,
      required: true,
    },
    totalAmount: {
      type: Number,
      default: 0,
    },
    selectDekisID: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      alertInfoMessage: this.alertInfo,
      bulkPartitionRate: 60,
      connecting: false,
      isBulkUpdatePartitionRate: false,
      isSelectiveDeletion: false,
      selectedDekispartId: "",
      tab: null,
      tabCloud: null,
      tabCloudGeneral: null,
      tabCloudWithoutKaisoku: null,
      tabCloudKaisoku: null,
      tabConstructionResult: null,
      tabDekispart: null,
      tabInnosite: null,
      tabs: [
        { name: this.$i18n.translate("dekispart") },
        { name: this.$i18n.translate("innosite.innosite") },
        { name: this.$i18n.translate("cloud.cloud") },
        { name: this.$i18n.translate("prodougu") },
        { name: this.$i18n.translate("dekisku") },
        { name: this.$i18n.translate("kjk") },
        { name: this.$i18n.translate("tsunaguweb") },
        { name: this.$i18n.translate("kentemtech") },
        { name: this.$i18n.translate("kentem_academy") },
        { name: this.$i18n.translate("xzero") },
        { name: this.$i18n.translate("lending_demo") },
        { name: this.$i18n.translate("other") },
        //{ name: this.$i18n.translate("kjk_price") },
      ],
      totalAmountPrice: this.totalAmount,
      productHeaders: this.headers,
      productItems: this.items,
      updatedProductItems: {},
      caseLogItems: "",
      dialog: false,
      confirmationMessage: "",
      caseCode: "",
      progress: 100,
      InnositeMaintenanceAlertMessage:
        "【リコーOSOの見積の複数本所有の保守取得は非対応。品種コードと金額が一致しません。手動にて選択願います】",
      DekispartVersionUpAlertMessage:
        "【一度も保守加入してないIDはバージョンアップボタン非対応】",
      CloudRemaingMonthAddAlertMessage:
        "【複数年加入の場合は、KSDBとSBだけ対応】",
      FildnetAddAlertMessage: "【フィールドネットの追加は手動でお願いします】",
      FildnetUpdateAlertMessage:
        "【フィールドネット５ライセンス以上所有の場合手動にて追加してください】",
    };
  },
  computed: {
    confirmationMessageWithLineBreaks: function () {
      return this.confirmationMessage.replace(/\r\n/g, "<br>");
    },
  },
  watch: {
    alertInfo: function (newAlertInfo, _) {
      this.alertInfoMessage = newAlertInfo;
    },
    headers: function (newHeaders, _) {
      this.productHeaders = newHeaders;
    },
    isBulkUpdatePartitionRate: function (newIsBulkUpdatePartitionRate, _) {
      this.$refs.productEditable.updateIsBulkUpdatePartitionRate(
        newIsBulkUpdatePartitionRate
      );
    },
    isSelectiveDeletion: function (newIsSelectiveDeletion, _) {
      this.$refs.productEditable.updateIsSelectiveDeletion(
        newIsSelectiveDeletion
      );
    },
    items: function (newItems, _) {
      this.productItems = newItems;
    },
    totalAmount: function (newTotalAmount, _) {
      this.totalAmountPrice = newTotalAmount;
    },
  },
  created: function () {
    this.selectedDekispartId = this.selectDekisID;
  },
  methods: {
    /**
     *
     */
    deleteItemAll() {
      if (typeof this.$refs.dekispartList !== "undefined") {
        this.$refs.dekispartList.deleteItemAll();
      }
      if (typeof this.$refs.innositeList !== "undefined") {
        this.$refs.innositeList.deleteItemAll();
      }
      if (typeof this.$refs.cloudList !== "undefined") {
        this.$refs.cloudList.deleteItemAll();
      }
      if (typeof this.$refs.dekispartSchoolList !== "undefined") {
        this.$refs.dekispartSchoolList.deleteItemAll();
      }
      if (typeof this.$refs.constructionInformationSystemList !== "undefined") {
        this.$refs.constructionInformationSystemList.deleteItemAll();
      }
      if (typeof this.$refs.tsunaguWebList !== "undefined") {
        this.$refs.tsunaguWebList.deleteItemAll();
      }
      if (typeof this.$refs.otherProductList !== "undefined") {
        this.$refs.otherProductList.deleteItemAll();
      }
      if (typeof this.$refs.prodouguList !== "undefined") {
        this.$refs.prodouguList.deleteItemAll();
      }
      if (typeof this.$refs.kentemTechList !== "undefined") {
        this.$refs.kentemTechList.deleteItemAll();
      }
      if (typeof this.$refs.kentemAcademyList !== "undefined") {
        this.$refs.kentemAcademyList.deleteItemAll();
      }
    },
    /**
     *
     * @param {Event} e
     */
    registerEstimateProduct(e) {
      const formData = new FormData(e.target);

      if (this.tab === "tab-" + this.$i18n.translate("dekispart")) {
        if (
          typeof this.$refs.dekispartDetail === "undefined" ||
          this.$refs.dekispartDetail.selectDekisID === ""
        ) {
          alert("デキスパートユーザーIDが設定されていません");
          return;
        }
        formData.append("ID", this.$refs.dekispartDetail.selectDekisID);
      }

      if (this.tab === "tab-" + this.$i18n.translate("innosite.innosite")) {
        if (
          typeof this.$refs.innositeDetail === "undefined" ||
          this.$refs.innositeDetail.selectInnositeID === ""
        ) {
          alert("イノサイトIDが設定されていません");
          return;
        }
        formData.append("ID", this.$refs.innositeDetail.selectInnositeID);
      }

      if (this.tab === "tab-" + this.$i18n.translate("cloud.cloud")) {
        if (
          typeof this.$refs.cloudDetail === "undefined" ||
          this.$refs.cloudDetail.selectCloudID === ""
        ) {
          alert("クラウドIDが設定されていません");
          return;
        }
        formData.append("ID", this.$refs.cloudDetail.selectCloudID);
      }

      if (this.tab === "tab-" + this.$i18n.translate("kjk")) {
        formData.append("ID", this.kjkId[0]);
      }

      if (this.tab === "tab-" + this.$i18n.translate("tsunaguweb")) {
        formData.append("ID", this.tsunaguId[0]);
      }

      if (this.tab === "tab-" + this.$i18n.translate("prodougu")) {
        formData.append("ID", this.prodouguId[0]);
      }

      if (this.tab === "tab-" + this.$i18n.translate("kentemtech")) {
        formData.append("ID", this.kentemTechId[0]);
      }

      if (this.tab === "tab-" + this.$i18n.translate("xzero")) {
        formData.append("ID", this.xzeroId[0]);
      }

      if (this.tab === "tab-" + this.$i18n.translate("lending_demo")) {
        formData.append("ID", this.lendingDemoId[0]);
      }

      if (this.tab === "tab-" + this.$i18n.translate("kentem_academy")) {
        formData.append("ID", this.kentemAcademyId[0]);
      }

      //オプションチェックエラー確認のための初期値
      formData.append("skipOptionCheck", 0);
      //重複チェックエラー確認のための初期値
      formData.append("skipDuplicateCheck", 0);
      //サポートチェックエラー確認のための初期値
      formData.append("skipSupportCheck", 0);
      //電気及び水道機械セットチェックエラー確認のための初期値
      formData.append("skipElectricOrWaterCrewSetCheck", 0);

      this.connecting = true;
      this.progress = 0;
      Axios.post(`/api/v1/estimate/product/register`, formData, {
        headers: { Authorization: "Bearer " + this.token },
      })
        .then((res) => {
          this.alertInfoMessage = res.data.message;
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(
                JSON.stringify(error.response.data.errors.kentem_product_id[0])
              );
              //オプションチェックを呼び出す
              this.checkProductOption(
                JSON.stringify(error.response.data.errors.kentem_product_id[0]),
                formData
              );
              //重複チェックを呼び出す
              this.checkDuplicateProduct(
                JSON.stringify(error.response.data.errors.kentem_product_id[0]),
                formData
              );
              //サポート商品チェックを呼び出す
              this.checkSupportProduct(
                JSON.stringify(error.response.data.errors.kentem_product_id[0]),
                formData
              );
              //電気及び水道機械セット商品チェックを呼び出す
              this.CheckElectricOrWaterCrewSet(
                JSON.stringify(error.response.data.errors.kentem_product_id[0]),
                formData
              );
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
      e.target.reset();
      this.deleteItemAll();
    },
    /**
     *
     * @param {Event} e
     */
    updateMaintenanceDifferenceEstimateProduct(e) {
      if (typeof this.$refs.dekispartDetail === "undefined") {
        alert("デキスパートユーザーIDが設定されていません");
        return;
      }

      const formData = new FormData(e.target);
      if (this.$refs.dekispartDetail.selectDekisID === "") {
        alert("デキスパートユーザーIDが設定されていません");
        return;
      }
      formData.append("ID", this.$refs.dekispartDetail.selectDekisID);

      this.connecting = true;
      this.progress = 0;
      Axios.post(`/api/v1/estimate/product/maintenance_difference`, formData, {
        headers: { Authorization: "Bearer " + this.token },
      })
        .then((res) => {
          this.alertInfoMessage = res.data.message;
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
      e.target.reset();
      this.deleteItemAll();
    },
    /**
     *
     * @param {Event} e
     */
    updateMaintenanceDifferenceInnosite(e) {
      if (typeof this.$refs.innositeDetail === "undefined") {
        alert("イノサイトIDが設定されていません");
        return;
      }

      if (this.$refs.innositeDetail.selectInnositeID === "") {
        alert("イノサイトIDが設定されていません");
        return;
      }

      const formData = new FormData(e.target);
      formData.append("ID", this.$refs.innositeDetail.selectInnositeID);

      this.connecting = true;
      this.progress = 0;
      Axios.post(
        `/api/v1/estimate/product/innosite/maintenance_difference`,
        formData,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
        .then((res) => {
          this.alertInfoMessage = res.data.message;
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
      e.target.reset();
      this.deleteItemAll();
    },
    /**
     *
     * @param {Event} e
     */
    updateVersionEstimateProduct(e) {
      const formData = new FormData(e.target);
      if (typeof this.$refs.dekispartDetail !== "undefined") {
        formData.append("ID", this.$refs.dekispartDetail.selectDekisID);
      }
      this.connecting = true;
      this.progress = 0;
      Axios.post(`/api/v1/estimate/product/versionup`, formData, {
        headers: { Authorization: "Bearer " + this.token },
      })
        .then((res) => {
          this.alertInfoMessage = res.data.message;
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
      e.target.reset();
      this.deleteItemAll();
    },
    /**
     *
     * @param {Event} e
     */
    updateCloudProduct(e) {
      if (typeof this.$refs.cloudDetail === "undefined") {
        alert("クラウドIDが設定されていません");
        return;
      }

      const formData = new FormData(e.target);
      if (typeof this.$refs.cloudDetail !== "undefined") {
        switch (this.$refs.cloudDetail.selectCloudID) {
          case "":
            alert("クラウドIDが設定されていません");
            return;
          case "新規ID":
            alert("新規IDでは更新できません");
            return;
        }
        formData.append("ID", this.$refs.cloudDetail.selectCloudID);
      }
      this.connecting = true;
      this.progress = 0;
      Axios.post(`/api/v1/estimate/product/cloud/renew/1`, formData, {
        headers: { Authorization: "Bearer " + this.token },
      })
        .then((res) => {
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              if (
                error.response.data.message ===
                "Trying to get property 'partition_rate_code' of non-object"
              ) {
                alert("仕切率が設定されていません");
                break;
              }
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
    },
    /**
     *
     * @param {Event} e
     */
    updateKaisokuNaviProductOneYear(e) {
      if (typeof this.$refs.cloudDetail === "undefined") {
        alert("クラウドIDが設定されていません");
        return;
      }

      const formData = new FormData(e.target);
      if (typeof this.$refs.cloudDetail !== "undefined") {
        switch (this.$refs.cloudDetail.selectCloudID) {
          case "":
            alert("クラウドIDが設定されていません");
            return;
          case "新規ID":
            alert("新規IDでは更新できません");
            return;
        }
        formData.append("ID", this.$refs.cloudDetail.selectCloudID);
      }
      this.connecting = true;
      this.progress = 0;
      Axios.post(`/api/v1/estimate/product/kaisoku_navi/renew/1`, formData, {
        headers: { Authorization: "Bearer " + this.token },
      })
        .then((res) => {
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              if (
                error.response.data.message ===
                "Trying to get property 'partition_rate_code' of non-object"
              ) {
                alert("仕切率が設定されていません");
                break;
              }
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
    },
    /**
     *
     * @param {Event} e
     */
    updateKaisokuNaviProductFiveYear(e) {
      if (typeof this.$refs.cloudDetail === "undefined") {
        alert("クラウドIDが設定されていません");
        return;
      }

      const formData = new FormData(e.target);
      if (typeof this.$refs.cloudDetail !== "undefined") {
        switch (this.$refs.cloudDetail.selectCloudID) {
          case "":
            alert("クラウドIDが設定されていません");
            return;
          case "新規ID":
            alert("新規IDでは更新できません");
            return;
        }
        formData.append("ID", this.$refs.cloudDetail.selectCloudID);
        formData.append(
          "management_sub_code",
          this.$refs.selectKaisokustd.managementSubCode
        );
      }
      this.connecting = true;
      this.progress = 0;
      Axios.post(`/api/v1/estimate/product/kaisoku_navi/renew/5`, formData, {
        headers: { Authorization: "Bearer " + this.token },
      })
        .then((res) => {
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              if (
                error.response.data.message ===
                "Trying to get property 'partition_rate_code' of non-object"
              ) {
                alert("仕切率が設定されていません");
                break;
              }
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
    },
    /**
     *
     * @param {Event} e
     */
    addCloudProduct(e) {
      if (typeof this.$refs.cloudDetail === "undefined") {
        alert("クラウドIDが設定されていません");
        return;
      }

      const formData = new FormData(e.target);
      if (typeof this.$refs.cloudDetail !== "undefined") {
        switch (this.$refs.cloudDetail.selectCloudID) {
          case "":
            alert("クラウドIDが設定されていません");
            return;
          case "新規ID":
            alert("新規IDでは更新できません");
            return;
        }
        formData.append("ID", this.$refs.cloudDetail.selectCloudID);
      }

      this.connecting = true;
      this.progress = 0;
      Axios.post(`/api/v1/estimate/product/cloud/add`, formData, {
        headers: { Authorization: "Bearer " + this.token },
      })
        .then((res) => {
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              if (error.response.data.message === "remaining months is null") {
                alert("残月数が取得できません");
                break;
              }
              if (error.response.data.message === "There are no months left") {
                alert("残月数が0以下の場合は使用できません");
                break;
              }
              if (
                error.response.data.message ===
                "The number of months remaining is more than 12 months"
              ) {
                alert("残月数が12か月を超える場合は使用できません");
                break;
              }
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
    },
    /**
     *
     * @param {Event} e
     */
    switchStdToAdv(e) {
      if (typeof this.$refs.cloudDetail === "undefined") {
        alert("クラウドIDが設定されていません");
        return;
      }

      const formData = new FormData(e.target);
      if (typeof this.$refs.cloudDetail !== "undefined") {
        switch (this.$refs.cloudDetail.selectCloudID) {
          case "":
            alert("クラウドIDが設定されていません");
            return;
          case "新規ID":
            alert("新規IDでは更新できません");
            return;
        }
        formData.append("ID", this.$refs.cloudDetail.selectCloudID);
      }

      this.connecting = true;
      this.progress = 0;
      Axios.post(
        `/api/v1/estimate/product/kaisoku_navi/std_to_adv/add`,
        formData,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
        .then((res) => {
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              if (error.response.data.message === "remaining months is null") {
                alert("残月数が取得できません");
                break;
              }
              if (error.response.data.message === "There are no months left") {
                alert("残月数が0以下の場合は使用できません");
                break;
              }
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
    },
    /**
     *
     * @param {Event} e
     */
    registerInnositeMaintenanceOneYear(e) {
      if (typeof this.$refs.innositeDetail === "undefined") {
        alert("イノサイトIDが設定されていません");
        return;
      }

      if (this.$refs.innositeDetail.selectInnositeID === "") {
        alert("イノサイトIDが設定されていません");
        return;
      }

      const formData = new FormData(e.target);
      formData.append("ID", this.$refs.innositeDetail.selectInnositeID);
      formData.append("year", 1);

      this.registerInnositeMaintenanceByFormData(formData);
    },
    /**
     *
     * @param {Event} e
     */
    registerInnositeMaintenanceFiveYear(e) {
      if (typeof this.$refs.innositeDetail === "undefined") {
        alert("イノサイトIDが設定されていません");
        return;
      }

      if (this.$refs.innositeDetail.selectInnositeID === "") {
        alert("イノサイトIDが設定されていません");
        return;
      }

      const formData = new FormData(e.target);
      formData.append("ID", this.$refs.innositeDetail.selectInnositeID);
      formData.append("year", 5);

      this.registerInnositeMaintenanceByFormData(formData);
    },
    /**
     *
     * @param {Event} e
     */
    registerDekispartMaintenanceOneYear(e) {
      const formData = new FormData(e.target);
      formData.append("year", 1);
      formData.append("ID", this.$refs.dekispartDetail.selectDekisID);
      this.registerDekispartMaintenanceByFormData(formData);
    },
    /**
     *
     * @param {Event} e
     */
    registerDekispartMaintenanceFiveYear(e) {
      const formData = new FormData(e.target);
      formData.append("year", 5);
      formData.append("ID", this.$refs.dekispartDetail.selectDekisID);
      this.registerDekispartMaintenanceByFormData(formData);
    },
    /**
     *
     * @param {FormData} formData
     */
    registerInnositeMaintenanceByFormData(formData) {
      this.registerMaintenanceByFormData(formData, "innosite");
    },
    /**
     *
     * @param {FormData} formData
     */
    registerDekispartMaintenanceByFormData(formData) {
      this.registerMaintenanceByFormData(formData, "dekispart");
    },
    /**
     *
     * @param {FormData} formData
     * @param {String} categoryName
     */
    registerMaintenanceByFormData(formData, categoryName) {
      this.connecting = true;
      this.progress = 0;
      Axios.post(
        "/api/v1/estimate/product/" + categoryName + "/maintenance/register",
        formData,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
        .then((res) => {
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
    },
    /**
     *
     * @param {String} id
     */
    onChangeCloudDetail(id) {
      if (typeof this.$refs.selectKaisokustd !== "undefined") {
        this.$refs.selectKaisokustd.cloudId = id;
      }
    },
    /**
     *
     * @param {Number} num
     */
    onUpdateTotalAmountPrice(num) {
      this.totalAmountPrice = num;
    },
    /**
     *
     */
    updateSelectKaisokustdCloudId() {
      this.$refs.selectKaisokustd.cloudId =
        this.$refs.cloudDetail.selectCloudID;
    },
    /**
     *
     * @param {String} errors
     * @param {FormData} formData
     */
    checkProductOption(errors, formData) {
      //オプションチェックのエラーか確認
      var result = errors.indexOf("オプションソフトとなるため");
      if (result !== -1) {
        if (window.confirm(`そのまま登録しますか？`)) {
          // OKが選択された時の処理
          formData.append("skipOptionCheck", 1);

          this.connecting = true;
          this.progress = 0;
          Axios.post(`/api/v1/estimate/product/register`, formData, {
            headers: { Authorization: "Bearer " + this.token },
          })
            .then((res) => {
              this.alertInfoMessage = res.data.message;
              this.totalAmountPrice = res.data.total_amount;
              this.productHeaders = res.data.headers;
              this.productItems = res.data.items;
              this.connecting = false;
              this.progress = 100;
            })
            .catch((error) => {
              this.connecting = false;
              this.progress = 100;
              console.log(error);
              switch (error.response.status) {
                case 401:
                  alert(
                    "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
                  );
                  break;
                case 404:
                  alert("接続先が見つかりませんでした。");
                  break;
                case 422:
                  alert(JSON.stringify(error.response.data.errors));
                  break;
                case 500:
                  alert("内部エラー");
                  break;
                default:
                  alert("通信エラーが発生しました、再度お試しください。");
                  break;
              }
            });
          this.deleteItemAll();
        }
      }
    },
    /**
     *
     * @param {String} errors
     * @param {FormData} formData
     */
    checkDuplicateProduct(errors, formData) {
      //重複チェックのエラーか確認
      var result = errors.indexOf("選択した商品は既に持っています");
      if (result !== -1) {
        if (window.confirm(`そのまま登録しますか？`)) {
          // OKが選択された時の処理
          formData.append("skipDuplicateCheck", 1);

          this.connecting = true;
          this.progress = 0;
          Axios.post(`/api/v1/estimate/product/register`, formData, {
            headers: { Authorization: "Bearer " + this.token },
          })
            .then((res) => {
              this.alertInfoMessage = res.data.message;
              this.totalAmountPrice = res.data.total_amount;
              this.productHeaders = res.data.headers;
              this.productItems = res.data.items;
              this.connecting = false;
              this.progress = 100;
            })
            .catch((error) => {
              this.connecting = false;
              this.progress = 100;
              console.log(error);
              switch (error.response.status) {
                case 401:
                  alert(
                    "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
                  );
                  break;
                case 404:
                  alert("接続先が見つかりませんでした。");
                  break;
                case 422:
                  alert(JSON.stringify(error.response.data.errors));
                  break;
                case 500:
                  alert("内部エラー");
                  break;
                default:
                  alert("通信エラーが発生しました、再度お試しください。");
                  break;
              }
            });
          this.deleteItemAll();
        }
      }
    },
    /**
     *
     * @param {String} errors
     * @param {FormData} formData
     */
    checkSupportProduct(errors, formData) {
      //サポート商品チェックのエラーか確認
      var result = errors.indexOf("3万未満のサポート");
      if (result !== -1) {
        if (window.confirm(`そのまま登録しますか？`)) {
          // OKが選択された時の処理
          formData.append("skipSupportCheck", 1);

          this.connecting = true;
          this.progress = 0;
          Axios.post(`/api/v1/estimate/product/register`, formData, {
            headers: { Authorization: "Bearer " + this.token },
          })
            .then((res) => {
              this.alertInfoMessage = res.data.message;
              this.totalAmountPrice = res.data.total_amount;
              this.productHeaders = res.data.headers;
              this.productItems = res.data.items;
              this.connecting = false;
              this.progress = 100;
            })
            .catch((error) => {
              this.connecting = false;
              this.progress = 100;
              console.log(error);
              switch (error.response.status) {
                case 401:
                  alert(
                    "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
                  );
                  break;
                case 404:
                  alert("接続先が見つかりませんでした。");
                  break;
                case 422:
                  alert(JSON.stringify(error.response.data.errors));
                  break;
                case 500:
                  alert("内部エラー");
                  break;
                default:
                  alert("通信エラーが発生しました、再度お試しください。");
                  break;
              }
            });
          this.deleteItemAll();
        }
      }
    },
    /**
     *
     * @param {String} errors
     * @param {FormData} formData
     */
    CheckElectricOrWaterCrewSet(errors, formData) {
      //サポート商品チェックのエラーか確認
      var result = errors.indexOf("電気及び水道機械セット");
      if (result !== -1) {
        if (window.confirm(`そのまま登録しますか？`)) {
          // OKが選択された時の処理
          formData.append("skipElectricOrWaterCrewSetCheck", 1);

          this.connecting = true;
          this.progress = 0;
          Axios.post(`/api/v1/estimate/product/register`, formData, {
            headers: { Authorization: "Bearer " + this.token },
          })
            .then((res) => {
              this.alertInfoMessage = res.data.message;
              this.totalAmountPrice = res.data.total_amount;
              this.productHeaders = res.data.headers;
              this.productItems = res.data.items;
              this.connecting = false;
              this.progress = 100;
            })
            .catch((error) => {
              this.connecting = false;
              this.progress = 100;
              console.log(error);
              switch (error.response.status) {
                case 401:
                  alert(
                    "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
                  );
                  break;
                case 404:
                  alert("接続先が見つかりませんでした。");
                  break;
                case 422:
                  alert(JSON.stringify(error.response.data.errors));
                  break;
                case 500:
                  alert("内部エラー");
                  break;
                default:
                  alert("通信エラーが発生しました、再度お試しください。");
                  break;
              }
            });
          this.deleteItemAll();
        }
      }
    },
    /**
     *
     * @param {String} value
     */
    setDekisID(value) {
      this.selectedDekispartId = value;
    },
    /**
     *
     */
    deleteSelectedItems() {
      if (!confirm("本当に削除しますか?")) {
        return;
      }
      this.$refs.productEditable.selectiveDeletionItems.forEach((element) =>
        this.$refs.productEditable.deleteItemWithoutConfirm(element)
      );
      this.$refs.productEditable.clearSelectiveDeletionItems();
    },
    /**
     *
     */
    bulkUpdatePartitionRate() {
      this.$refs.productEditable.bulkUpdatePartitionRate(
        this.bulkPartitionRate
      );
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusPartitionRateTarget(status) {
      this.$refs.productEditable.changeStatusPartitionRateTarget(status);
    },
    /**
     *
     * @param {Bool} status
     */
    changeStatusDeleteTarget(status) {
      this.$refs.productEditable.changeStatusDeleteTarget(status);
    },
    /**
     *
     * @param {Event} e
     */
    openDialog(e) {
      this.getCaseLogInformation(e);
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    /**
     *
     */
    confirmAction() {
      this.closeDialog();
      this.createEstimateKjk(this.estimateId, this.caseCode);
    },
    /**
     *
     * @param {Event} e
     */
    getCaseLogInformation(e) {
      const formData = new FormData(e.target);
      this.connecting = true;
      this.progress = 0;
      Axios.post(`/api/v1/case_log/information`, formData, {
        headers: { Authorization: "Bearer " + this.token },
      })
        .then((response) => {
          this.caseLogItems = response.data;
          this.confirmationMessage = response.data[0].body;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              if (
                error.response.data.message ===
                "Trying to get property 'partition_rate_code' of non-object"
              ) {
                alert("仕切率が設定されていません");
                break;
              }
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
    },
    /**
     *
     * @param {Number} estimateId
     * @param {String} caseCode
     */
    createEstimateKjk(estimateId, caseCode) {
      this.connecting = true;
      this.progress = 0;
      Axios.post(
        `/api/v1/estimate/product/kjk/add`,
        {
          estimate_id: estimateId,
          case_code: caseCode,
        },
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      )
        .then((res) => {
          this.totalAmountPrice = res.data.total_amount;
          this.productHeaders = res.data.headers;
          this.productItems = res.data.items;
          this.connecting = false;
          this.progress = 100;
        })
        .catch((error) => {
          this.connecting = false;
          this.progress = 100;
          console.log(error);
          switch (error.response.status) {
            case 401:
              alert(
                "アクセストークンの有効期限切れなどの理由により、通信に失敗しました。リロードしてください。"
              );
              break;
            case 404:
              alert("接続先が見つかりませんでした。");
              break;
            case 422:
              alert(JSON.stringify(error.response.data.errors));
              break;
            case 500:
              if (
                error.response.data.message ===
                "Trying to get property 'partition_rate_code' of non-object"
              ) {
                alert("仕切率が設定されていません");
                break;
              }
              alert("内部エラー");
              break;
            default:
              alert("通信エラーが発生しました、再度お試しください。");
              break;
          }
        });
    },
  },
};
</script>

<style>
.multiselect__content-wrapper {
  position: static !important;
}
</style>
