<template>
  <div>
    <template v-if="progress === 100 && contents && ref !== null">
      <v-container>
        <v-tabs>
          <v-tab
            v-for="tab in tabs"
            :key="tab.name"
          >
            所有状況
          </v-tab>
          <v-tab-item>
            <v-container>
              <v-row>
                <v-col
                  cols="4"
                  sm="6"
                >
                  <v-card>
                    <v-col>
                      <v-card>
                        <v-data-iterator
                          v-if="contents.data.reference_numbers[ref].dekispart"
                          :items="
                            contents.data.reference_numbers[ref].dekispart
                              .products
                          "
                          hide-default-footer
                          :items-per-page="1000"
                        >
                          <template #default="props">
                            <v-row class="pre-scrollable">
                              <v-simple-table dense>
                                <tbody
                                  v-if="
                                    contents.data.reference_numbers[ref]
                                      .dekispart
                                  "
                                  style="font-size: 110%"
                                >
                                  <tr>
                                    <td>会員期間 :</td>
                                    <td v-if="contents.data.reference_numbers[ref].dekispart.contract_start_date">
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.is_canceled
                                          | convertBooleanMaintenanceText
                                      }}
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.is_updating
                                          | convertBooleanUpdatingText
                                      }}
                                      <br>
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.contract_start_date
                                      }}～{{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.contract_end_date
                                      }}
                                    </td>
                                    <td v-else>未加入</td>
                                  </tr>
                                  <tr>
                                    <td>残月数 :</td>
                                    <td v-if="contents.data.reference_numbers[ref].dekispart.contract_start_date">
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.remaining_month_of_contract
                                          | formatRemainingMonths
                                      }}
                                    </td>
                                    <td v-else />
                                  </tr>
                                  <tr>
                                    <td>会員種 :</td>
                                    <td>
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.membership_type_name
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>更新方法 :</td>
                                    <td v-if="contents.data.reference_numbers[ref].dekispart.contract_start_date">
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.deposit_type_name
                                      }}
                                    </td>
                                    <td v-else />
                                  </tr>
                                  <tr>
                                    <td>販売店1 :</td>
                                    <td v-if="contents.data.reference_numbers[ref].dekispart.contract_start_date">
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.dealership_name1
                                      }}
                                    </td>
                                    <td v-else />
                                  </tr>
                                  <tr>
                                    <td>販売店2 :</td>
                                    <td v-if="contents.data.reference_numbers[ref].dekispart.contract_start_date">
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.dealership_name2
                                      }}
                                    </td>
                                    <td v-else />
                                  </tr>
                                  <tr>
                                    <td>業務委託 :</td>
                                    <td v-if="contents.data.reference_numbers[ref].dekispart.contract_start_date">
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.subcontracting_cost
                                          | convertSubcontractingCostText
                                      }}
                                    </td>
                                    <td v-else />
                                  </tr>
                                  <tr>
                                    <td>備考 :</td>
                                    <td v-if="contents.data.reference_numbers[ref].dekispart.contract_start_date">
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.remarks_update
                                      }}
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.remarks_plural
                                      }}
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.remarks_automatic_transfer
                                      }}
                                      {{
                                        contents.data.reference_numbers[ref]
                                          .dekispart.remarks_others
                                      }}
                                    </td>
                                    <td v-else />
                                  </tr>
                                  <p style="color: blue">
                                    所有ソフト
                                  </p>
                                  <tr
                                    v-for="item in props.items"
                                    :key="item.id"
                                  >
                                    <td colspan="2" v-if="item.is_prin">  
                                      {{ item.name }}
                                    </td>
                                  </tr>
                                </tbody>
                                <p v-else>
                                  情報が取得できませんでした。
                                </p>
                              </v-simple-table>
                            </v-row>
                          </template>
                        </v-data-iterator>
                      </v-card>
                    </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
        </v-tabs>
      </v-container>
    </template>
  </div>
</template>




<script>
import Axios from "axios";
import Token from "./token";

export default {
  filters: {
    addComma: (val) => {
      return val.toLocaleString();
    },
    convertBooleanText: (val) => {
      return val === true ? "はい" : "いいえ";
    },
    convertBooleanMaintenanceText: (val) => {
      return val === true ? "退会" : "加入中";
    },
    convertBooleanUpdatingText: (val) => {
      return val === true ? "更新案内中" : "";
    },
    convertBooleanProvisionalMemberText: (val) => {
      return val === true ? "申込中" : "いいえ";
    },
    convertBooleanDekispartSchoolPriceText: (val) => {
      return val === true ? "申込価格" : "価格";
    },
    convertSubcontractingCostText: (val) => {
      return val === -1 ? "有" : "無";
    },
    formatRemainingMonths: (val) => {
      const isMinus = val < 0;
      val = Math.abs(val);
      const year = (val / 12) | 0;
      const month = val - year * 12;
      let str = isMinus ? "- " : "";
      str += year + " 年 " + month + " ヶ月";
      return str;
    },
  },
  props: {
    kentemId: {
      type: String,
      required: true,
    },
    kentemIdUrl: {
      type: String,
      default: "https://id.kentem.com",
    },
    reference: {
      type: Number,
      required: false,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      contents: "",
      downloading: true,
      progress: 0,
      tabs: [{ name: "デキスパート" }],
      dekispartTitleRecords: [{ key: "user_id", name: "ID" }],
      dekispartRecords: [
        { key: "user_id", name: "ユーザーID" },
        { key: "contract_start_date", name: "開始日" },
        { key: "contract_end_date", name: "満了日" },
        { key: "membership_type_name", name: "会員種別" },
        { key: "deposit_type_name", name: "入金種類" },
      ],
      baseUrl: this.KentemIdUrl + "/",
      url: this.kentemIdUrl + "/api/v1/kentem/detail/",
      tokenUrl: "/api/v1/token",
      bearer: "",
      ref: 0,
    };
  },
  watch: {
    kentemId: function (newKentemId) {
      this.url = "/api/v1/kentem/detail/";
      this.url = this.url + newKentemId;
      this.getTokenAndLoadList();
    },
    reference: function (newReference) {
      this.ref = newReference;
    },
  },
  created: function () {
    this.ref = this.reference;
    this.url = this.url + this.kentemId;
    this.getTokenAndLoadList();
  },

  methods: {
    /**
     * 
     */
    async loadList() {
      await Axios.get(this.url, {
        onDownloadProgress: this.onDownload,
        headers: {
          Authorization: this.bearer,
        },
      })
        .then((res) => {
          if (res.data) {
            this.contents = res.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.downloading = false;
      this.progress = 100;
    },
    /**
     * 
     * @param {Object} e 
     */
    onDownload(e) {
      this.progress = Math.floor((e.loaded * 100) / e.total);
    },
    /**
     * 
     */
    async getTokenAndLoadList() {
      this.bearer = await Token(this.tokenUrl);
      this.loadList();
    },
  },
};
</script>

<style>
.pre-scrollable {
  max-height: 280px;
  overflow-y: scroll;
}
</style>